import bigInt from "big-integer";
/* eslint-disable no-bitwise */
/**
 *
 * @param {number, string} bitset - создает массив из битовой маски
 */

export const getBitsArray = (bitset) => {
  if (!bitset) return [];
  const value = bigInt(bitset);
  let b = bigInt(1);
  const res = [];

  while (b.lesserOrEquals(value)) {
    if (b & value) res.push(b.toString());
    b = b.shiftLeft(1);
  }

  return res;
};

/**
 * Функция перевода битовой суммы из числа в массив строк
 * с учетом вложенных родительских id у чекбоксов
 *
 * @param {string} bitsum - битовая сумма
 * @param {array} dictionary - словарь типов
 */
export const getArrayFromBitsum = (bitsum, dictionary) => {
  if (!dictionary && !bitsum) return [];

  const bitsumBigNumber = bigInt(bitsum);
  // переворот строки нужен чтобы сравнивать с первого бита, так проще
  const arrayFromBitsum = Array.from(bitsumBigNumber.toString(2)).reverse();

  const result = [];
  let checkedNumber = 0;
  dictionary.forEach((type) => {
    const dictType = bigInt(type.Id);

    // если тип больше чем вся сумма, он не может в нее входить
    if (dictType.gt(bitsumBigNumber)) return;

    // получим массив такого типа: ["1", "0", "0", "0"]
    const dictTypeArray = Array.from(dictType.toString(2)).reverse();

    let isContain = true;
    let sum = 0;
    for (let i = 0; i < dictTypeArray.length; i++) {
      sum += parseInt(dictTypeArray[i], 10);
      // здесь ищем несовпадение битов у типа и суммы
      // если единички не совпали, то этот тип не включен в bitsum
      if (dictTypeArray[i] === "1" && arrayFromBitsum[i] !== dictTypeArray[i]) {
        isContain = false;
        break;
      }
    }

    if (isContain) {
      result.push(type.Id);
      // если тип не составной, то в нем только один значащий бит
      if (sum === 1) {
        checkedNumber++;
      }
    }
  });

  return { values: result, checkedNumber };
};

export const isBitChild = (bit, bitset) => Boolean(bit & bitset);
