import { reaction, makeAutoObservable, observable } from "mobx";
import api from "../../api";
import addMetric from "../../utils/addMetric";
import { tabsStrings } from "../strings";
import { isClient } from "../../utils/isClient";
import { tendersTabs, urls } from "../../const";

export const getTabsOptions = (strings, locale = "ru") => [
  {
    id: tendersTabs.TENDER_SEARCH_TAB,
    label: strings[locale].tenderSearch,
    disabled: false,
    count: 0,
  },
  {
    id: tendersTabs.TENDER_SUBSCRIPTIONS_TAB,
    label: strings[locale].subscriptions,
    disabled: true,
    tooltip: strings[locale].subscriptionsTooltip,
    showTooltip: true,
    count: 0,
  },
  {
    id: tendersTabs.TENDER_OFFERS_TAB,
    label: strings[locale].tenderOffers,
    disabled: false,
    href: urls.responses,
    showLoginPopup: true,
    count: 0,
  },
  {
    id: tendersTabs.TENDER_ADD_TAB,
    label: strings[locale].addTender,
    disabled: false,
    count: 0,
    href: urls.tendersAdd,
    isBlueButton: true,
  },
];
class Tabs {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.locale = this.rootStore.localeModule.locale;
    this.tabs = [];
    makeAutoObservable(this);

    reaction(
      () => this.activeTab,
      (activeTab) => {
        if (activeTab === tendersTabs.TENDER_SUBSCRIPTIONS_TAB) {
          this.rootStore.filterSubscribeModule.getGeoRequest();
          addMetric("tender-subscribe-open-page");
        }
      },
    );

    reaction(
      () => this.rootStore.globalModule.isAuthorized,
      (event) => {
        if (event) {
          this.tabs = this.tabs.map((tab) => {
            if (tab.id === tendersTabs.TENDER_SUBSCRIPTIONS_TAB) {
              return { ...tab, showTooltip: false, disabled: false };
            }
            return tab;
          });
        }
      },
    );

    reaction(
      () => this.counts,
      (event) => {
        if (event) {
          this.tabs = this.tabs.map((tab) => {
            if (tab.id === tendersTabs.TENDER_OFFERS_TAB) {
              return {
                ...tab,
                count: this.counts.tenderBidEventsCount,
              };
            }
            return tab;
          });
        }
      },
    );
    this.init();
  }

  @observable counts = {};

  setTabs = () => {
    this.tabs = getTabsOptions(tabsStrings, this.locale);
  };

  activeTab = tendersTabs.TENDER_SEARCH_TAB;

  init = () => {
    if (isClient) {
      this.getTenderCountersRequest();
    }
    this.setTabs();
  };

  setActiveTab = (value) => {
    this.activeTab = value;
  };

  getTenderCountersRequest = () => api.getTenderCounters()
    .then(({ data }) => {
      this.counts = data;
    })
    .catch((e) => console.error(e));
}

export default Tabs;
