import React, { useEffect, useState } from "react";

import Button from "@components/button";
import getMobileOperatingSystem from "../../../utils/getMobileOperatingSystem";
import atiLogo from "../../../img/ati.svg";
import shareLogo from "../../../img/share.svg";

import addMetric from "../../../utils/addMetric";

import i18nStrings from "./strings";

import {
  Wrapper,
  AndroidInformer,
  IphoneInformer,
  InforemrTitle,
  InforemrContent,
  InforemrDescription,
  CloseButton,
} from "./AddToHomeScreen.styles";
import { useStore } from "../../../store/RootStore";
import { isClient } from "../../../utils/isClient";

const AddToHomeScreen = () => {
  const store = useStore();
  const [showA2HS, setShowA2HS] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const system = getMobileOperatingSystem();
  const isMobileSystem = ["android", "ios"].includes(system);

  const { locale } = store.localeModule;

  const strings = i18nStrings[locale || "ru"];

  const handleShowA2HS = () => {
    setShowA2HS(true);
    addMetric("tender-show-tenders-a2hs-informer");
  };

  useEffect(() => {
    if (isClient && localStorage) {
      const showInformer = localStorage.getItem("tenders-a2hs-informer");
      const isStandalone = isClient && window.matchMedia("(display-mode: standalone)").matches;

      if (!showInformer && showInformer !== "true" && isMobileSystem && !isStandalone) {
        if (isClient && "getInstalledRelatedApps" in window.navigator) {
          window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            handleShowA2HS();
          });

          setTimeout(() => {
            if (!deferredPrompt) {
              handleShowA2HS();
            }
          }, 2000);
        } else {
          handleShowA2HS();
        }
      }
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("tenders-a2hs-informer", true);
    setShowA2HS(false);
    addMetric("tender-close-tenders-a2hs-informer");
  };

  const handleClick = () => {
    deferredPrompt.prompt();

    deferredPrompt.userChoice.then(() => {
      handleClose();
      setDeferredPrompt(null);
    });
  };

  const renderAndroidInformer = () => {
    if (deferredPrompt) {
      return (
        <AndroidInformer>
          <div className="content">
            <img alt="" className="ati-icon" height={45} src={atiLogo} width={45} />
            <InforemrTitle className="title">{strings.title}</InforemrTitle>
          </div>
          <div className="button-wrapper">
            <Button primary size="small" onClick={handleClick}>
              {strings.add}
            </Button>
          </div>
          <CloseButton onClick={handleClose} />
        </AndroidInformer>
      );
    }

    return (
      <AndroidInformer>
        <div className="content">
          <img alt="" className="ati-icon" height={45} src={atiLogo} width={45} />
          <InforemrTitle className="title">{strings.otherTitle}</InforemrTitle>
        </div>
        <CloseButton onClick={handleClose} />
      </AndroidInformer>
    );
  };

  const renderIphoneInformer = () => (
    <IphoneInformer>
      <InforemrTitle className="mobile">{strings.title}</InforemrTitle>
      <InforemrContent>
        <img alt="" className="ati-icon" height={45} src={atiLogo} width={45} />
        <div>
          <InforemrTitle className="tablet">{strings.title}</InforemrTitle>
          <InforemrDescription>
            {strings.description.start} <img alt="" className="share-icon" height={16} src={shareLogo} width={16} /> {strings.description.end}
          </InforemrDescription>
        </div>
      </InforemrContent>
      <CloseButton onClick={handleClose} />
    </IphoneInformer>
  );

  if (!showA2HS || !isMobileSystem) return null;

  return (
    <Wrapper>
      {system === "android" && renderAndroidInformer()}
      {system === "ios" && renderIphoneInformer()}
    </Wrapper>
  );
};

export default AddToHomeScreen;
