import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { availableLocalesEnum } from "../const";
import { snakeToCamelObj, camelToSnakeObj } from "./objectHelpers";

const api = axios.create();

api.interceptors.response.use((response: AxiosResponse) => {
  if (response.data) {
    response.data = snakeToCamelObj(response.data);
  }

  return response;
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };

  if (newConfig.headers?.["Content-Type"] === "multipart/form-data") {
    return newConfig;
  }

  if (config.params) {
    newConfig.params = camelToSnakeObj(config.params);
  }

  if (config.data) {
    newConfig.data = camelToSnakeObj(config.data);
  }

  return newConfig;
});

function buildApiUrl(serviceName, servicePort) {
  return `http://${process.env.CONSUL_ENV}.${serviceName}.service.${process.env.CONSUL_DOMAIN}:${servicePort}`;
}

interface ClientWrapperConfig extends AxiosRequestConfig {
  method: "GET" | "POST" | "PUT" | "DELETE",
  userId?: string,
  locale?: availableLocalesEnum
  secret?: string,
  serviceName?: string,
  port?: number,
  url?: string,
}

const clientWrapper = async <ResponseModel, RequestModel = void>(config: ClientWrapperConfig, request?:RequestModel): Promise<AxiosResponse<ResponseModel>> => {
  const defaultConfigValues = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const outputConfig = {
    ...defaultConfigValues,
    method: config.method,
    url: config.url,
    data: config.data,
    headers: {
      ...defaultConfigValues.headers,
      ...config.headers,
    },
  };

  if (config.serviceName && config.port && config.url) {
    outputConfig.url = `${buildApiUrl(config.serviceName, config.port)}${config.url}`;
  }

  if (config.url && !config.serviceName && !config.port) {
    outputConfig.url = config.url;
  }

  if (config.userId) {
    outputConfig.headers["X-Authenticated-UserId"] = config.userId;
  }

  if (config.locale) {
    outputConfig.headers["Accept-Language"] = config.locale;
  }

  if (config.secret) {
    outputConfig.headers["X-API-Secret"] = config.secret;
  }

  outputConfig.data = request;

  const result = await api(outputConfig);
  return result;
};

export default clientWrapper;
