import api from "../../api/api";
import { snakeToCamelObj } from "../../utils/objectHelpers";
import RootStore from "../RootStore";
import {
  GetCitiesResponse,
  GetCountriesResponse,
  GetRegionsResponse,
  City,
  Region,
  Country,
} from "../../api-types/swagger/GeoTypes";

type fetchGeoType = {
  countries: Record<number, Country>
  regions: Record<number, Region>
  cities: Record<number, City>
}

class GeoStore {
  rootStore: RootStore;
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  prepareCountries = (geoInfo: GetCountriesResponse) => {
    if ("countries" in geoInfo) {
      return geoInfo.countries.reduce((acc, country) => {
        acc[country.countryId] = country;
        return acc;
      }, {});
    }

    return {};
  };

  prepareRegions = (geoInfo: GetRegionsResponse, countriesResponse: GetCountriesResponse) => {
    if ("regions" in geoInfo) {
      return geoInfo.regions.reduce((acc, region) => {
        const currentCountry = countriesResponse?.countries?.find(
          (country) => region.countryId === country.countryId,
        );
        acc[region.regionId] = {
          ...region,
          countryName: currentCountry?.name,
        };
        return acc;
      }, {});
    }

    return {};
  };

  prepareCities = (geoInfo: GetCitiesResponse, regionsResponse: GetRegionsResponse, countriesResponse: GetCountriesResponse) => {
    if ("cities" in geoInfo) {
      return geoInfo.cities.reduce((acc, city) => {
        const currentRegion = regionsResponse?.regions?.find((region) => city.regionId === region.regionId);
        const currentCountry = countriesResponse?.countries?.find(
          (country) => city.countryId === country.countryId,
        );

        acc[city.cityId] = {
          ...city,
          regionName: currentRegion?.name,
          countryName: currentCountry?.name,
        };
        return acc;
      }, {});
    }

    return {};
  };

  fetchGeo = async (cityIds: number[] = [], regionIds: number[] = [], countryIds: number[] = []): Promise<fetchGeoType> => {
    try {
      const allCities: number[] = cityIds;
      const allRegions: number[] = regionIds;
      const allCountries: number[] = countryIds;

      let citiesResponse: GetCitiesResponse = { cities: [] };
      let regionsResponse: GetRegionsResponse = { regions: [] };
      let countriesResponse: GetCountriesResponse = { countries: [] };

      if (cityIds.length) {
        const response = await api.getGeoCities({ ids: allCities });
        citiesResponse = response.data;
        citiesResponse.cities.forEach((city) => {
          if (city?.regionId && !allRegions.includes(city?.regionId)) {
            allRegions.push(city.regionId);
          }
          if (city?.countryId && !allCountries.includes(city.countryId)) {
            allCountries.push(city.countryId);
          }
        });
      }

      if (regionIds.length) {
        const response = await api.getGeoRegions({ ids: allRegions });
        regionsResponse = response.data;
        regionsResponse.regions.forEach((region) => {
          if (region?.countryId && !allCountries.includes(region.countryId)) {
            allCountries.push(region.countryId);
          }
        });
      }

      if (countryIds.length) {
        const response = await api.getGeoCountries({ ids: allCountries });
        countriesResponse = response.data;
      }

      return {
        countries: snakeToCamelObj(this.prepareCountries(countriesResponse)),
        regions: snakeToCamelObj(this.prepareRegions(regionsResponse, countriesResponse)),
        cities: snakeToCamelObj(this.prepareCities(citiesResponse, regionsResponse, countriesResponse)),
      };
    } catch (error) {
      console.log(error);
      return Promise.reject();
    }
  };
}

export default GeoStore;
