import React from "react";
import { ThemeProvider } from "styled-components";
import dynamic from "next/dynamic";
import { StoreProvider, rootStore } from "../store/RootStore";
import GlobalStyles from "../globalStyles";
import ErrorBoundary from "../components/Common/ErrorBoundary";
import { availableLocalesEnum } from "../const";
import "ati-ui-react/unstyled/components/Button/v3/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Button/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Link/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Autosuggest/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Form/Field/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/InputField/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/OptionsSelector/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Select/v3/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Checkbox/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/ButtonGroup/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Skeleton/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/widgets/QuickFirmsSearch/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/widgets/Pagination/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Popup/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/components/Heading/styles.css"; // TODO перейти на новые компоненты
import "ati-ui-react/unstyled/widgets/DropdownMenuButton/styles.css"; // TODO перейти на новые компоненты

import theme from "../theme";
import { TendersSearchProps } from "../types";
import AddToHomeScreen from "../components/Common/AddToHomeScreen/AddToHomeScreen";
import { isClient } from "../utils/isClient";

const LoginPopupWrapper = dynamic(() => import("../components/Common/LoginPopup/LoginPopupWrapper"), { ssr: false });
const ErrorNotification = dynamic(() => import("../components/Common/ErrorNotification"), { ssr: false });

const App = ({ Component, pageProps }: { Component: any, pageProps: TendersSearchProps }) => (
  <ThemeProvider theme={theme}>
    <StoreProvider store={rootStore(pageProps.locale)}>
      <ErrorNotification />
      <ErrorBoundary locale={pageProps.locale || availableLocalesEnum.ru}>
        <Component {...pageProps} />
      </ErrorBoundary>
      <GlobalStyles />
      {isClient && <LoginPopupWrapper />}
      {!pageProps?.isAtiApp && <AddToHomeScreen />}
    </StoreProvider>
  </ThemeProvider>
  );
export default App;
