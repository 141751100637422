import { makeAutoObservable } from "mobx";
import { isClient } from "../../utils/isClient";

export default class Media {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.checkDesktop = isClient && window.matchMedia("(min-width: 1180px)");
    this.checkTablet = isClient && window.matchMedia("(min-width: 652px) and (max-width: 1179px)");
    this.checkMobile = isClient && window.matchMedia("(max-width: 651px)");
    this.init();
    makeAutoObservable(this);
  }

  init = () => {
    if (isClient) {
      this.checkDesktop.addListener((event) => {
        if (event.matches) this.setDevice("desktop");
      });
      this.checkTablet.addListener((event) => {
        if (event.matches) this.setDevice("tablet");
      });
      this.checkMobile.addListener((event) => {
        if (event.matches) this.setDevice("mobile");
      });
      this.setDeviceOnLoad();
    }
  };

  setDeviceOnLoad = () => {
    if (this.checkDesktop.matches) {
      this.setDevice("desktop");
    }
    if (this.checkTablet.matches) {
      this.setDevice("tablet");
    }
    if (this.checkMobile.matches) {
      this.setDevice("mobile");
    }
  };

  device = null;

  get isDesktop() {
    return this.device === "desktop";
  }

  get isTablet() {
    return this.device === "tablet";
  }

  get isMobile() {
    return this.device === "mobile";
  }

  setDevice = (value) => {
    this.device = value;
  };
}
