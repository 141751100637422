import { makeAutoObservable } from "mobx";
import { availableLocalesEnum } from "../../const";

class LocaleStore {
  constructor(locale) {
    makeAutoObservable(this);

    this.locale = locale || availableLocalesEnum.ru;
  }

  getStrings = (strings) => strings[this.locale];
}

export { LocaleStore };
