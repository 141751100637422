const ru = {
  title: "Добавьте\u00A0приложение ATI.SU\u00A0Тендеры на\u00A0главный\u00A0экран",
  description: {
    start: "Нажмите на значок",
    end: "и выберите «На экран Домой»",
  },
  otherTitle: "Добавьте приложение ATI.SU\u00A0Тендеры на\u00A0главный экран — нажмите на многоточие и выберите «Добавить на гл. экран»",
  add: "Добавить",
};

const en = {
  title: "Add\u00A0the ATI.SU\u00A0Tenders app\u00A0to the Home\u00A0screen",
  description: {
    start: "Click the icon",
    end: "and select Home Screen",
  },
  otherTitle: "Add the ATI.SU Tenders app to the Home screen. To do this, tap the ellipsis and select \"Add to Home Screen\".",
  add: "Add",
};

export default { ru, en };
