import Image from "next/image";
import Button from "@components/button";
import ErrorSVGIcon from "../../../../img/error.svg";
import i18nStrings from "./strings";
import {
  ErrorMessageStyles,
  ErrorMessageTitle,
  ErrorMessageText,
  ErrorMessageContent,
  ErrorMessageIcon,
} from "./ErrorMessage.styles";

const ErrorMessage = ({ locale }) => {
  const strings = i18nStrings[locale];

  const handleReloader = () => {
    if (typeof document !== "undefined") {
      document.location.reload();
    }
  };

  return (
    <ErrorMessageStyles>
      <ErrorMessageIcon>
        <Image alt="" height={120} src={ErrorSVGIcon} width={120} />
      </ErrorMessageIcon>
      <ErrorMessageContent>
        <ErrorMessageTitle>{strings.errorMessageTitle}</ErrorMessageTitle>
        <ErrorMessageText>{strings.errorMessageText}</ErrorMessageText>
        <Button onClick={handleReloader}>{strings.reloadPage}</Button>
      </ErrorMessageContent>
    </ErrorMessageStyles>
  );
};

export default ErrorMessage;
