import { action, makeObservable } from "mobx";

class FormBaseClass {
  constructor() {
    makeObservable(this, {
      setValue: action,
      setValuesCounter: action,
      onChangeField: action,
      reverseFields: action,
    });
  }
  valuesCounter = 0;

  setValue = (group, field, value) => {
    this.values[group][field] = value;
  };

  setValuesCounter = () => {
    const counter = this.getTouchedCounter();
    this.valuesCounter = counter;
  };

  get isNotEmpty() {
    return JSON.stringify(this.values) !== JSON.stringify(this.defaultValue);
  }

  onChangeField = (group, field, value) => {
    this.setValue(group, field, value);
    this.setValuesCounter();
  };

  onChangeFieldOutput = (group, field, value) => {
    this.setValue(group, field, value);
    this.rootStore.globalModule.onSearch({ initial: false });
  };

  onEnter = (event) => {
    if (event.key === "Enter") {
      this.rootStore.globalModule.onSearch();
    }
  };

  clearForm = () => {
    this.values = this.defaultValue;
    this.valuesCounter = 0;
  };

  reverseFields = (group) => {
    const { from, to } = this.values[group];
    const fromValue = Number(from.replace(",", "."));
    const toValue = Number(to.replace(",", "."));

    if ((fromValue && toValue) && fromValue > toValue) {
      this.values[group].from = to;
      this.values[group].to = from;
    }
  };

  getTouchedCounter = () => {
    let counter = 0;
    const categoryKeys = Object.keys(this.values);
    categoryKeys.forEach((categoryKey) => {
      const fieldKeys = Object.keys(this.values[categoryKey]);
      fieldKeys.forEach((fieldKey) => {
        const filedDefaultValue = this.defaultValue[categoryKey][fieldKey];
        const fieldValue = this.values[categoryKey][fieldKey];
        if (JSON.stringify(filedDefaultValue) !== JSON.stringify(fieldValue)) {
          counter++;
        }
      });
    });
    return counter;
  };
}

export default FormBaseClass;
