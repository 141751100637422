import { action, makeObservable, observable, override, reaction } from "mobx";
import FormBaseClass from "../FormBaseClass";
import { prepareCurrencyOptions, prepareServerData } from "./helpers";
import { priceFormat, isPrice } from "../../../utils/priceFormat";
import { betOptions } from "../../strings";

class FormPayment extends FormBaseClass {
  constructor(rootStore) {
    super();
    this.rootStore = rootStore;
    this.locale = this.rootStore.localeModule.locale;

    this.betOptions = betOptions[this.locale];

    this.currencyOptions = [];
    this.countryId = null;

    this.defaultValue = {
      paymentGroup: {
        withNDS: false,
        withoutNDS: false,
        cash: false,
      },
      delay: { from: "", to: "" },
      bet: {
        from: "",
        currency: null,
        betType: this.betOptions[0],
        prepaid: false,
      },
    };
    this.values = this.defaultValue;

    reaction(
      () => this.rootStore.globalModule.dictionaries,
      (value) => {
        this.showForm = true;
        const preparedCurrency = prepareCurrencyOptions(value.Currencies);
        this.currencyOptions = preparedCurrency;
        this.defaultValue.bet.currency = this.currencyOptions.find((item) => item.countryId?.includes(this.countryId)) || this.currencyOptions[0];
        this.values.bet.currency = this.currencyOptions.find((item) => item.countryId?.includes(this.countryId)) || this.currencyOptions[0];
      },
    );

    reaction(
      () => this.rootStore.globalModule?.profile,
      (value) => {
        this.countryId = value.country_id;
      },
    );

    makeObservable(this, {
      onChangeField: override,
      reverseFields: override,
      values: observable,
      onChangeSummField: action,
      validateDelayField: action,
      onChangeDelayField: action,
      onBlurField: action,
      getServerData: action,
      getQueryData: action,
      setQueryData: action,
    });
  }

  onChangeSummField = (field, value) => {
    if (value && !isPrice(value) || value.length > 12) return;
    this.onChangeField("bet", field, priceFormat(value));
  };

  validateDelayField = (value) => {
    const pattern = /^0$|^[1-9][0-9]{0,4}$/g;
    if (value === "") return true;
    return pattern.test(value);
  };

  onChangeDelayField = (field, value) => {
    const isValid = this.validateDelayField(value);
    if (isValid) {
      this.onChangeField("delay", field, value);
    }
  };

  onBlurField = (group) => {
    this.reverseFields(group);
  };

  getServerData = () => {
    const result = prepareServerData(this);
    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    return serverData;
  };

  setQueryData = (data) => {
    this.values.paymentGroup.withNDS = Boolean(data.cashless_with_vat);
    this.values.paymentGroup.withoutNDS = Boolean(data.cashless_without_vat);
    this.values.delay.from = data.deferment_payment_from ? `${data.deferment_payment_from}` : "";
    this.values.delay.to = data.deferment_payment_to ? `${data.deferment_payment_to}` : "";
    this.values.bet.from = data.rate_from ? `${data.rate_from}` : "";
    this.values.bet.betType = this.betOptions.find((item) => item.value === data.rate_volume_unit_id) || this.betOptions[0];
    this.values.bet.currency = this.currencyOptions?.find((item) => item.value === data.currency_id)
    || this.currencyOptions.find((item) => item.countryId?.includes(this.countryId))
    || this.currencyOptions[0];
    this.values.bet.prepaid = Boolean(data.with_prepayment);
  };
}

export default FormPayment;
