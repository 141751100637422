import { action, makeObservable, observable } from "mobx";
import FormBaseClass from "../FormBaseClass";
import { outputOptions } from "../../strings";

class FormExtra extends FormBaseClass {
  constructor(rootStore) {
    super();
    this.rootStore = rootStore;
    this.locale = this.rootStore.localeModule.locale;

    this.outputOptions = outputOptions[this.locale];

    this.defaultValue = {
      output: { select: this.outputOptions[0] },
    };
    this.values = this.defaultValue;
    makeObservable(this, {
      values: observable,
      getServerData: action,
      getQueryData: action,
      setQueryData: action,
    });
  }

  getServerData = () => {
    const { output } = this.values;
    const result = {};

    if (output.select.value) result.order_by = output.select.value;

    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    return serverData;
  };

  setQueryData = (data) => {
    this.values.output.select = this.outputOptions.find((item) => item.value === data.order_by) || this.outputOptions[0];
  };
}

export default FormExtra;
