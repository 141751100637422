import { makeAutoObservable } from "mobx";
import { enableStaticRendering } from "mobx-react-lite";
import React, { FC, ReactElement, ReactNode, createContext, useContext } from "react";
import GlobalStore from "./GlobalStore";
import GeoStore from "./GeoStore";
import { LocaleStore } from "./Locale/Locale";
import FilterSubscribe from "./FilterSubscribe/FilterSubscribe";
import FormsStore from "./Forms";
import MediaStore from "./Media";
import NotificationStore from "./Notification";
import TabsStore from "./Tabs";
import ListPopupStore from "./ListPopup";
import LoginPopupStore from "./LoginPopup";
import SubscribePopupStore from "./SubscribePopup";
import URLQueryStore from "./URLQuery";
import SourceButtons from "./SourceButtons";

enableStaticRendering(typeof window === "undefined");

export interface IRootStore {
  locale: string;
  mediaModule: MediaStore;
  geoModule: GeoStore;
  globalModule: GlobalStore;
  localeModule: LocaleStore;
  formsModule: FormsStore;
  notificationModule: NotificationStore;
  tabsModule: TabsStore;
  listPopupModule: ListPopupStore;
  loginPopupModule: LoginPopupStore;
  subscribePopupModule: SubscribePopupStore;
  URLQueryModule: URLQueryStore;
  sourceButtonsModule: SourceButtons;
  filterSubscribeModule: FilterSubscribe;
}

class RootStore implements IRootStore {
  locale: string;
  mediaModule: MediaStore;
  globalModule: GlobalStore;
  geoModule: GeoStore;
  localeModule: LocaleStore;
  formsModule: FormsStore;
  notificationModule: NotificationStore;
  tabsModule: TabsStore;
  listPopupModule: ListPopupStore;
  loginPopupModule: LoginPopupStore;
  subscribePopupModule: SubscribePopupStore;
  URLQueryModule: URLQueryStore;
  sourceButtonsModule: SourceButtons;
  filterSubscribeModule: FilterSubscribe;

  constructor(locale) {
    this.locale = locale;

    makeAutoObservable(this);

    this.mediaModule = new MediaStore(this);
    this.localeModule = new LocaleStore(this.locale);
    this.geoModule = new GeoStore(this);
    this.globalModule = new GlobalStore(this);
    this.formsModule = new FormsStore(this);
    this.notificationModule = new NotificationStore(this);
    this.tabsModule = new TabsStore(this);
    this.listPopupModule = new ListPopupStore(this);
    this.loginPopupModule = new LoginPopupStore();
    this.subscribePopupModule = new SubscribePopupStore();
    this.URLQueryModule = new URLQueryStore(this);
    this.sourceButtonsModule = new SourceButtons(this);
    this.filterSubscribeModule = new FilterSubscribe(this, this.locale);
  }
}

export type StoreComponent = FC<{
  store: IRootStore;
  children: ReactNode;
}>;

export const rootStore = (locale) => new RootStore(locale);
export const StoreContext = createContext<IRootStore>(rootStore as unknown as IRootStore);

export const StoreProvider: StoreComponent = ({ children, store }): ReactElement => <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
export const useStore = (): RootStore => useContext(StoreContext);
export default RootStore;
