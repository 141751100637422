import React from "react";
import ErrorMessage from "./ErrorMessage";
import { availableLocalesEnum } from "../../../const";

type ErrorBoundaryProps = {
  children: React.ReactNode;
  locale: availableLocalesEnum,
};
type ErrorBoundaryState = {
  hasError: Error | null;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.SENTRY_ENABLE) {
      window.Sentry.captureException(error, errorInfo.componentStack);
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage locale={this.props.locale} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
