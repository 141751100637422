export function extendedSearchListener(event, form) {
  let parsedEvent;
  if (!event || !event.data) return;

  try {
    parsedEvent = JSON.parse(event.data);
    if (!parsedEvent || !parsedEvent.eventType) {
      return;
    }
  } catch (e) {
    return;
  }
  switch (parsedEvent.eventType) {
    case "selectExtendedSearchPopup":
      form.onChangeField("firm", "autosuggest", parsedEvent.data.nameRus);
      form.selectedFirm = parsedEvent.data;
      form.closeExtendedSearchPopup();
      break;
    case "closeExtendedSearchPopup":
      form.closeExtendedSearchPopup();
      break;
    default:
      break;
  }
}
