import { makeAutoObservable } from "mobx";
import { options } from "../strings";

class SourceButtons {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.locale = this.rootStore.localeModule.locale;
    this.sourceOptions = options[this.locale];

    makeAutoObservable(this);
  }

  currentSource = null;

  setCurrentSource = (value) => {
    this.currentSource = value;
  };

  getQueryData = () => {
    if (this.currentSource !== null) {
      return { tender_type: this.currentSource };
    }

    return {};
  };

  setQueryData = (data) => {
    this.currentSource = data.tender_type;
  };
}

export default SourceButtons;
