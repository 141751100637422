import { NOT_AVAILABLE_FOR_CONTACT } from "../const/errorTypes";

export const betOptions = {
  ru: [
    { value: 0, label: "за контракт" },
    { value: 1, label: "за перевозку" },
    { value: 2, label: "за тонну" },
    { value: 3, label: "за м³" },
  ],
  en: [
    { value: 0, label: "per contract" },
    { value: 1, label: "per shipment" },
    { value: 2, label: "per ton" },
    { value: 3, label: "per m³" },
  ],
};

export const adrOptions = {
  ru: [
    { value: null, label: "Любой ADR" },
    { value: 1, label: "ADR-1" },
    { value: 2, label: "ADR-2" },
    { value: 3, label: "ADR-3" },
    { value: 4, label: "ADR-4" },
    { value: 5, label: "ADR-5" },
    { value: 6, label: "ADR-6" },
    { value: 7, label: "ADR-7" },
    { value: 8, label: "ADR-8" },
    { value: 9, label: "ADR-9" },
  ],
  en: [
    { value: null, label: "Any ADR" },
    { value: 1, label: "ADR-1" },
    { value: 2, label: "ADR-2" },
    { value: 3, label: "ADR-3" },
    { value: 4, label: "ADR-4" },
    { value: 5, label: "ADR-5" },
    { value: 6, label: "ADR-6" },
    { value: 7, label: "ADR-7" },
    { value: 8, label: "ADR-8" },
    { value: 9, label: "ADR-9" },
  ],
};

export const resultListErrors = {
  ru: {
    [NOT_AVAILABLE_FOR_CONTACT]: "У вас нет доступа к этой Площадке",
  },
  en: {
    [NOT_AVAILABLE_FOR_CONTACT]: "You do not have access to this Platform",
  },
};

export const tabsStrings = {
  ru: {
    tenderSearch: "Поиск тендеров",
    subscriptions: "Подписки",
    subscriptionsTooltip: "Войдите, чтобы посмотреть свои подписки",
    tenderOffers: "Ваши предложения",
    addTender: "Добавить тендер",
  },
  en: {
    tenderSearch: "Search for tenders",
    subscriptions: "Subscriptions",
    subscriptionsTooltip: "Sign in to see your subscriptions",
    tenderOffers: "Your offers",
    addTender: "Add a tender",
  },
};

export const errorMessage = {
  ru: {
    loadingError: "Ошибка загрузки",
    boardLoadingError: "Ошибка загрузки площадок",
    somethingWrong: "Перезагрузите страницу, что-то пошло не так.",
    searchError: "Ошибка поиска",
    errorReceivingCities: "Ошибка получения городов",
    companySearchError: "Ошибка поиска фирм",
  },
  en: {
    loadingError: "Loading error",
    boardLoadingError: "Board loading error",
    somethingWrong: "Please reload the page, something went wrong.",
    searchError: "Search error",
    errorReceivingCities: "Error receiving cities",
    companySearchError: "Company search error",
  },
};

export const options = {
  ru: [
    {
      label: "Все",
      value: null,
    },
    {
      label: "ATI.SU",
      value: 0,
    },
    {
      label: "Другие источники",
      value: 1,
    },
  ],
  en: [
    {
      label: "All",
      value: null,
    },
    {
      label: "ATI.SU",
      value: 0,
    },
    {
      label: "Other sources",
      value: 1,
    },
  ],
};

export const outputOptions = {
  ru: [
    { value: 0, label: "новые тендеры" },
    { value: 1, label: "макс. сумма контракта" },
    { value: 2, label: "макс. срок до окончания" },
  ],

  en: [
    { value: 0, label: "new tenders" },
    { value: 1, label: "max contract amount" },
    { value: 2, label: "maximum time until the end" },
  ],
};

export const statusOptions = {
  ru: [
    { value: "0", label: "Все" },
    { value: "3,4", label: "Завершённые и отменённые" },
    { value: "1,2", label: "Активные и анонсированные" },
  ],
  en: [
    { value: "0", label: "All" },
    { value: "3,4", label: "Completed and canceled" },
    { value: "1,2", label: "Active and announced" },
  ],
};

export const viewsOptions = {
  ru: [
    { value: null, label: "Все виды" },
    { value: 1, label: "Коммерческие" },
    { value: 2, label: "Государственные" },
  ],
  en: [
    { value: null, label: "All types" },
    { value: 1, label: "Commercial" },
    { value: 2, label: "Government" },
  ],
};

export const pointOptions = {
  ru: [
    { value: null, label: "Любой балл участника ATI.SU" },
    { value: 5, label: "5 звёзд" },
    { value: 4, label: "не меньше 4-х звёзд" },
    { value: 3, label: "не меньше 3-х звёзд" },
    { value: 2, label: "не меньше 2-х звёзд" },
    { value: 1, label: "не меньше 1-й звёзд" },
  ],
  en: [
    { value: null, label: "Any ATI.SU participant score" },
    { value: 5, label: "5 stars" },
    { value: 4, label: "no less than 4 stars" },
    { value: 3, label: "no less than 3 stars" },
    { value: 2, label: "no less than 2 stars" },
    { value: 1, label: "no less than 1 star" },
  ],
};

export const firmListTypeOptions = {
  ru: [
    { value: 1, label: "Искать по выбранным спискам" },
    { value: 2, label: "Исключить выбранные списки" },
  ],
  en: [
    { value: 1, label: "Search through selected lists" },
    { value: 2, label: "Exclude selected lists" },
  ],
};
