export const isPrice = (value) => value.match(/^[1-9](\d|\s)*?((,|\.)\d{0,2})?$/);

export const parsePrice = (value) => {
  const parsed = parseFloat(value.replace(/\s/g, "").replace(",", "."));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(parsed)) return null;
  return parsed;
};

export const priceFormat = (value, full = false) => {
  if (typeof value !== "number" && typeof value !== "string") return null;

  let str = value;
  if (typeof value === "number") {
    str = value.toString();
  }

  str = str.replace(/\s/g, "");

  const isComma = str.includes(",");
  const isDot = str.includes(".");
  if (isComma && isDot) return null;

  // eslint-disable-next-line no-nested-ternary
  const delimiter = isComma ? "," : isDot ? "." : null;

  let [int, frac] = str.split(delimiter);
  int = int
    .replace(new RegExp(`^(\\d{${(int.length % 3 ? int.length % 3 : 0)}})(\\d{3})`, "g"), "$1\u202f$2")
    .replace(/(\d{3})+?/gi, "$1\u202f")
    .trim();
  if (full && frac && frac.length === 1) frac += "0";
  return `${int}${delimiter ? "," : ""}${frac ? `${frac}` : ""}`;
};
