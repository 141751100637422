import { action, makeObservable, observable, override } from "mobx";
import FormBaseClass from "../FormBaseClass";
import { validateField } from "./helpers";
import { adrOptions } from "../../strings";

class FormCargoParams extends FormBaseClass {
  constructor(rootStore) {
    super();
    this.rootStore = rootStore;
    this.locale = this.rootStore.localeModule.locale;
    this.adrOptions = adrOptions[this.locale];

    this.defaultValue = {
      adr: { select: this.adrOptions[0] },
      lengthField: { from: "", to: "" },
      widthField: { from: "", to: "" },
      heigthField: { from: "", to: "" },
    };
    this.values = this.defaultValue;

    makeObservable(this, {
      setValue: override,
      setValuesCounter: override,
      reverseFields: override,
      values: observable,
      onChangeFieldCargoForm: action,
      onChangeSelect: action,
      onBlurField: action,
      getServerData: action,
      getQueryData: action,
      setQueryData: action,
    });
  }

  onChangeFieldCargoForm = (group, field, value) => {
    const isValid = validateField(value);
    if (isValid) {
      this.setValue(group, field, value);
      this.setValuesCounter();
    }
  };

  onChangeSelect = (option) => {
    this.setValue("adr", "select", option);
    this.setValuesCounter();
  };

  onBlurField = (group) => {
    this.reverseFields(group);
  };

  getServerData = () => {
    this.values;
    const { adr, lengthField, widthField, heigthField } = this.values;
    const result = {};

    if (adr.select.value) result.cargo_adr = adr.select.value;
    if (lengthField.from) result.cargo_length_from = +lengthField.from.replace(",", ".");
    if (lengthField.to) result.cargo_length_to = +lengthField.to.replace(",", ".");
    if (widthField.from) result.cargo_width_from = +widthField.from.replace(",", ".");
    if (widthField.to) result.cargo_width_to = +widthField.to.replace(",", ".");
    if (heigthField.from) result.cargo_height_from = +heigthField.from.replace(",", ".");
    if (heigthField.to) result.cargo_height_to = +heigthField.to.replace(",", ".");

    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    return serverData;
  };

  setQueryData = (data) => {
    this.values.adr.select = this.adrOptions.find((item) => item.value === data.cargo_adr) || this.adrOptions[0];
    this.values.lengthField.from = data.cargo_length_from ? `${data.cargo_length_from}` : "";
    this.values.lengthField.to = data.cargo_length_to ? `${data.cargo_length_to}` : "";
    this.values.widthField.from = data.cargo_width_from ? `${data.cargo_width_from}` : "";
    this.values.widthField.to = data.cargo_width_to ? `${data.cargo_width_to}` : "";
    this.values.heigthField.from = data.cargo_height_from ? `${data.cargo_height_from}` : "";
    this.values.heigthField.to = data.cargo_height_to ? `${data.cargo_height_to}` : "";
  };
}

export default FormCargoParams;
