import truncate from "../utils/truncate";
import invertColor from "../utils/invertColor";
import { snakeToPascalObj, snakeToCamelObj, simpleArrayToDict } from "../utils/objectHelpers";
import { commonBoard } from "../const";

const truncateWithoutSpaces = (value) => {
  const strArray = value.split(" ");
  const findedLongText = strArray.find((text) => text.length > 24);

  if (findedLongText) {
    return truncate(value, 24);
  }

  return value;
};

export function prepareDictionaries(dictionaries) {
  return snakeToPascalObj(dictionaries);
}

export function prepareDictionariesForCards(dictionaries) {
  const convertedDict = snakeToCamelObj(dictionaries);
  const result = {};

  Object.entries(convertedDict).forEach(([key, value]) => {
    result[key] = simpleArrayToDict(value, "id");
  });

  return result;
}

export const getBoardInfo = (boardData) => {
  if (!boardData) {
    return null;
  }

  return {
    bgColor: boardData.isCommon ? "var(--glz-color-neutral-tone-4)" : boardData.color,
    color: boardData.isCommon ? "var(--glz-color-neutral-tone-0)" : invertColor(boardData.color),
    id: boardData.id,
    name: boardData.name ?? boardData.originalName,
    availableForContact: boardData.isCommon ? true : boardData.availableForContact,
  };
};

export function prepareBoards(boards) {
  const result = boards.filter((board) => board.availableForContact).map((board) => {
    const boardInfo = getBoardInfo(board);

    return {
      id: board.id,
      name: boardInfo && boardInfo.name,
      color: boardInfo && boardInfo.color,
      bgColor: boardInfo && boardInfo.bgColor,
      shortName: truncateWithoutSpaces(boardInfo && boardInfo.name, 24),
      availableForContact: boardInfo && boardInfo.availableForContact,
    };
  });

  return [commonBoard, ...result];
}

export function prepareBoardsForCards(boards) {
  const transformed = boards.map((board) => {
    const boardInfo = getBoardInfo(board);
    return {
      id: board.id,
      boardId: board.id,
      bgColor: boardInfo && boardInfo.bgColor,
      color: boardInfo && boardInfo.color,
      name: boardInfo && boardInfo.name,
      availableForContact: boardInfo && boardInfo.availableForContact,
    };
  });
  const result = simpleArrayToDict(transformed, "id");

  result.a0a0a0a0a0a0a0a0a0a0a0a0 = commonBoard;

  return result;
}

export const getGeoBody = (geo) => {
  const uniqArray = [...new Set(geo)].filter(Boolean);
  return uniqArray;
};

export function getTenderCities(data) {
  let cities = [];
  let regions = [];
  let countries = [];

  data.forEach(({ tender }) => {
    tender.trips.forEach((trip) => {
      trip.routes.forEach((route) => {
        const { extraPoints, fromCityId, toCityId, fromCountryId, toCountryId, fromRegionId, toRegionId } = route;
        const extraPointCities = extraPoints.map((extraPoint) => extraPoint.cityId);
        cities = [...cities, fromCityId, toCityId, ...extraPointCities];
        regions = [...regions, fromRegionId, toRegionId];
        countries = [...countries, fromCountryId, toCountryId];
      });
    });
  });

  return {
    cities: getGeoBody(cities),
    regions: getGeoBody(regions),
    countries: getGeoBody(countries),
  };
}

export function getTenderContacts(data) {
  let contacts = [];

  data.forEach(({ tender }) => {
    if (tender.atiId) {
      const firmContacts = tender.contacts.map((contactId) => `${tender.atiId}.${contactId}`);
      contacts = [...contacts, ...firmContacts];
    }
  });

  return [...new Set(contacts)];
}
