import getEnvironDomain from "ati-utils/getEnvironDomain";
import clientWrapper from "../utils/clientWrapper";
import {
  TenderSearchResult,
  Dictionaries,
  TenderCounters,
  CreateFilterSubscriptionRequest,
  FilterSubscriptionPublic,
} from "../api-types/swagger/TenderTypes";
import {
  GetCitiesByIdsRequest,
  GetCitiesResponse,
  GetCountriesByIdsRequest,
  GetCountriesResponse,
  GetRegionsByIdsRequest,
  GetRegionsResponse,
  SuggestionsRequest,
  SuggestionsResponse,
} from "../api-types/swagger/GeoTypes";
import { Account } from "../api-types/swagger/FirmServiceTypes";
import { BoardDigestView } from "../api-types/swagger/Boards";
import { FirmListTooltipElementListTooltipPublic } from "../api-types/swagger/Lists";
import { FullPaymentsInfo } from "../api-types/swagger/Billing";
import { prepareGeoRequest, prepareResponse } from "./geoServiceAdapter";
import { snakeToCamelObj } from "../utils/objectHelpers";

const services = {
  tenders: {
    serviceName: "mercury",
    servicePort: 5731,
  },
  atlas: {
    serviceName: "atlas",
    servicePort: 5783,
  },
  firmsService: {
    serviceName: "firms-service",
    servicePort: 80,
  },
  billing: {
    serviceName: "billing-user",
    servicePort: 7722,
  },
};

const XClientName = "tenders-search-ssr";

class Api {
  getTenders = (data, isAuthorized = false) => {
    const url = isAuthorized
      ? "/gw/tenders/v1/tenders/search"
      : "/gw/tenders/public/v1/tenders/search";
    const response = clientWrapper<TenderSearchResult>(
      {
        method: "POST",
        url,
        headers: {
          "X-Client-Name": XClientName,
        },
      },
      data,
    );
    return response;
  };

  getCities = async (data) => {
    const preparedData = prepareGeoRequest(snakeToCamelObj(data));
    const response = await clientWrapper<SuggestionsResponse, SuggestionsRequest>(
      {
        method: "POST",
        url: "/gw/gis-dict/public/v1/autocomplete/suggestions",
        headers: {
          "X-Client-Name": XClientName,
        },
      },
      preparedData,
    );

    const preparedResponse = prepareResponse(response.data);

    response.data = preparedResponse as SuggestionsResponse;

    return response;
  };

  getDictionaries = async () => {
    const response = await clientWrapper<Dictionaries>({
      method: "GET",
      url: "/gw/tenders/public/v2/dictionaries",
      headers: {
        "X-Client-Name": XClientName,
      },
    });

    return response;
  };

  getGeoCities = (request: GetCitiesByIdsRequest) => {
    const response = clientWrapper<GetCitiesResponse, GetCitiesByIdsRequest>(
      {
        method: "POST",
        url: "/gw/gis-dict/public/v1/geo/cities/by-ids",
        headers: {
          "X-Client-Name": XClientName,
        },
      },
      request,
    );

    return response;
  };

  getGeoRegions = (request: GetRegionsByIdsRequest) => {
    const response = clientWrapper<GetRegionsResponse, GetRegionsByIdsRequest>(
      {
        method: "POST",
        url: "/gw/gis-dict/public/v1/geo/regions/by-ids",
        headers: {
          "X-Client-Name": XClientName,
        },
      },
      request,
    );

    return response;
  };

  getGeoCountries = (request: GetCountriesByIdsRequest) => {
    const response = clientWrapper<GetCountriesResponse, GetCountriesByIdsRequest>(
      {
        method: "POST",
        url: "/gw/gis-dict/public/v1/geo/countries/by-ids",
        headers: {
          "X-Client-Name": XClientName,
        },
      },
      request,
    );

    return response;
  };

  getAccount = async (userId: string) => {
    const userFirmId = +userId.split(".")[0];

    const response = await clientWrapper<Account>({
      method: "GET",
      port: services.firmsService.servicePort,
      serviceName: services.firmsService.serviceName,
      url: `/_internal/accounts/${userFirmId}`,
      headers: {
        "X-Client-Name": XClientName,
      },
    });

    return response;
  };

  getFirms = async (data: any) => {
    const response = await fetch(
      `//${getEnvironDomain()}/webapi/extendedsearch/v1/firms`,
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return response.json();
  };

  getBoards = async () => {
    const response = await clientWrapper<BoardDigestView>({
      method: "GET",
      url: "/webapi/boards/v2/boards/frontend/board/userCanView",
      headers: {
        "X-Client-Name": XClientName,
      },
    });

    return response;
  };

  getLists = async (userId) => {
    const response = await clientWrapper<FirmListTooltipElementListTooltipPublic>({
        method: "GET",
        url: "/webapi/scrolls/v1/firms/short",
        userId,
        headers: {
          "X-Client-Name": XClientName,
        },
      });

    return response;
  };

  getTenderCounters = async (userId: string) => {
    const response = await clientWrapper<TenderCounters>({
      method: "GET",
      userId,
      url: "/gw/tenders/v1/tenders/counters/firms",
      headers: {
        "X-Client-Name": XClientName,
      },
    });

    return response;
  };

  sendFilterSubscriptions = async (
    request: CreateFilterSubscriptionRequest,
  ) => {
    const response = await clientWrapper<
      CreateFilterSubscriptionRequest,
      FilterSubscriptionPublic
    >(
      {
        method: "POST",
        url: "/gw/tenders/v1/tenders/subscriptions",
        headers: {
          "X-Client-Name": XClientName,
        },
      },
      request,
    );

    return response;
  };

  getFilterSubscriptions = async (userId: string) => {
    const response = await clientWrapper<FilterSubscriptionPublic>({
      method: "GET",
      url: "/gw/tenders/v1/tenders/subscriptions",
      userId,
      headers: {
        "X-Client-Name": XClientName,
      },
    });

    return response;
  };

  deleteFilterSubscriptions = async (id: string) => {
    const response = await clientWrapper({
      method: "DELETE",
      url: `/gw/tenders/v1/tenders/subscriptions/${id}`,
      headers: {
        "X-Client-Name": XClientName,
      },
    });

    return response;
  };

  getContactStatus = async (data: any) => {
    const response = await fetch(
      `//chat.${getEnvironDomain()}/presence/status`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data),
      },
    );

    return response;
  };

  sendActivity = async (data: any) => {
    const response = await clientWrapper(
      {
        method: "POST",
        url: "/gw/datalake_front_receiver/v1/send_activity",
        headers: {
          "X-Client-Name": XClientName,
        },
      },
      data,
    );

    return response;
  };

  validationEmail = async (email: string) => {
    const response = await clientWrapper(
      {
        method: "POST",
        url: "/gw/firms/v1.0/firms/validation/email",
      },
      `"${email}"`,
    );

    return response;
  };

  getActiveLicenses = async (userId: string) => {
    const userFirmId = +userId.split(".")[0];
    const response = await clientWrapper<FullPaymentsInfo>({
      method: "GET",
      port: services.billing.servicePort,
      serviceName: services.billing.serviceName,
      url: `/_internal/billing/full-payment-extend-info/${userFirmId}`,
      secret: "9a0dc63ee64e7c7ceca757273150fbf46934dc73",
      headers: {
        "X-Client-Name": XClientName,
      },
    });

    return response;
  };
}

const api = new Api();

export default api;
