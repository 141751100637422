import { action, computed, makeObservable, observable, override, reaction } from "mobx";
import FormBaseClass from "../FormBaseClass";
import { NOT_AVAILABLE_FOR_CONTACT } from "../../../const/errorTypes";

class FormBoards extends FormBaseClass {
  constructor(rootStore) {
    super();

    this.rootStore = rootStore;
    this.defaultValue = {
      optionSelector: {
        boardsList: [],
      },
    };
    this.values = this.defaultValue;

    makeObservable(this, {
      setValuesCounter: override,
      values: observable,
      serverBoardList: observable,
      showClearButton: computed,
      showSelectedAllButton: computed,
      onSelectBoard: action,
      selectAllBoards: action,
      clearBoardList: action,
      clearForm: action,
      getServerData: action,
      setQueryData: action,
    });

    reaction(
      () => this.rootStore.globalModule.boards,
      (value) => {
        this.serverBoardList = value;
      },
    );

    reaction(
      () => this.values?.optionSelector?.boardsList,
      () => this.setValuesCounter(),
    );

    reaction(
      () => this.serverBoardList,
      () => {
        this.values.optionSelector.boardsList = this.serverBoardList.map((board) => board.id);
      },
    );
  }

  serverBoardList = [];

  get showClearButton() {
    return Boolean(this.values.optionSelector.boardsList.length);
  }

  get showSelectedAllButton() {
    return this.serverBoardList.length !== this.values.optionSelector.boardsList.length;
  }

  onSelectBoard = (boardId) => {
    const findedId = this.values.optionSelector.boardsList.find((id) => id === boardId);
    if (findedId) {
      this.values.optionSelector.boardsList = this.values.optionSelector.boardsList.filter((id) => id !== boardId);
    } else {
      this.values.optionSelector.boardsList = [...this.values.optionSelector.boardsList, boardId];
    }
  };

  selectAllBoards = () => {
    this.values.optionSelector.boardsList = this.serverBoardList.map((board) => board.id);
  };

  clearBoardList = () => {
    this.values.optionSelector.boardsList = [];
  };

  clearForm = () => {
    this.clearBoardList();
  };

  getServerData = () => {
    this.values;
    const result = {};

    if (!this.showSelectedAllButton) return result;

    if (this.values.optionSelector.boardsList.length) {
      result.board_ids = this.values.optionSelector.boardsList;
    }
    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    return serverData;
  };

  setQueryData = (data) => {
    if (!this.rootStore.globalModule?.isAuthorized) return;

    const loadedBoards = Object.keys(this.rootStore.globalModule?.boardsForCards);
    if (data.board_ids && loadedBoards.length) {
      const filteredBoards = data.board_ids.filter((boardId) => {
        const currentBoard = this.rootStore.globalModule?.boardsForCards[boardId];
        return currentBoard?.availableForContact;
      });

      if (!filteredBoards.length) throw new Error(NOT_AVAILABLE_FOR_CONTACT);
      this.values.optionSelector.boardsList = filteredBoards || [];
      return;
    }

    this.values.optionSelector.boardsList = data.board_ids || [];
  };
}

export default FormBoards;
