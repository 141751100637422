import { tendersTabs } from "../../const";

class URLQuery {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.queryParams = {};
    this.filterData = {};
  }

  init = (query) => {
    this.queryParams = query;
  };

  setQueryAllForms = () => {
    if (this.queryParams.subscriptions) {
      this.rootStore.tabsModule.activeTab = tendersTabs.TENDER_SUBSCRIPTIONS_TAB;
    }

    if (this.queryParams.filter) {
      const data = JSON.parse(window.decodeURI(this.queryParams.filter));
      this.filterData = data;
      this.rootStore.formsModule.direction.setQueryData(data);
      this.rootStore.formsModule.carParams.setQueryData(data);
      this.rootStore.formsModule.carTypes.setQueryData(data);
      this.rootStore.formsModule.cargoParams.setQueryData(data);
      this.rootStore.formsModule.payment.setQueryData(data);
      this.rootStore.formsModule.boards.setQueryData(data);
      this.rootStore.formsModule.extra.setQueryData(data);
      this.rootStore.formsModule.output.setQueryData(data);
      this.rootStore.formsModule.firms.setQueryData(data);
      this.rootStore.sourceButtonsModule.setQueryData(data);
    }
  };

  setQueryFilterParamsIntoUrl = () => {
    const directionData = this.rootStore.formsModule.direction.getQueryData();
    const carParamsData = this.rootStore.formsModule.carParams.getQueryData();
    const carTypesData = this.rootStore.formsModule.carTypes.getQueryData();
    const cargoParamsData = this.rootStore.formsModule.cargoParams.getQueryData();
    const firmsData = this.rootStore.formsModule.firms.getQueryData();
    const paymentData = this.rootStore.formsModule.payment.getQueryData();
    const extraData = this.rootStore.formsModule.extra.getQueryData();
    const outputData = this.rootStore.formsModule.output.getQueryData();
    const boardsData = this.rootStore.formsModule.boards.getQueryData();
    const sourceButtonsData = this.rootStore.sourceButtonsModule.getQueryData();
    const urlData = {
      ...directionData,
      ...carParamsData,
      ...carTypesData,
      ...cargoParamsData,
      ...paymentData,
      ...boardsData,
      ...extraData,
      ...outputData,
      ...firmsData,
      ...sourceButtonsData,
    };
    const filterRegexpPattern = /^(\?filter=).+?&|^(\?filter=)(.+)$/;
    const filterUrlString = Object.keys(urlData).length ? `filter=${window.encodeURI(JSON.stringify(urlData))}` : "";
    const otherQueryParams = window.location.search.replace(filterRegexpPattern, "").replace("?", "");
    const queryParamsArray = [filterUrlString, otherQueryParams].filter(Boolean);
    const urlString = queryParamsArray.length ? `?${queryParamsArray.join("&")}` : "";

    if (urlString) {
      window.history.pushState("", "filter", urlString);
    } else {
      this.clearQueryFilterParamsToUrl();
    }
  };

  clearQueryFilterParamsToUrl = () => {
    window.history.pushState("", "filter", window.location.origin + window.location.pathname);
  };

  getQueryStringParams = () => {
    const result = Object.entries(this.queryParams).reduce((acc, [key, value]) => {
      if (key !== "filter") {
        acc += `&${key}=${value}`;
      }
      return acc;
    }, "");

    return result;
  };
}

export default URLQuery;
