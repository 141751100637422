export enum availableLocalesEnum {
  ru = "ru",
  en = "en"
}

export const geoLanguageEnum = {
  ru: 0,
  en: 1,
};

export const tendersTabs = {
  TENDER_SEARCH_TAB: "tender_search_tab",
  TENDER_SUBSCRIPTIONS_TAB: "tender_subscriptions_tab",
  TENDER_OFFERS_TAB: "tender_offers_tab",
  TENDER_ADD_TAB: "tender_add_tab",
};

export const urls = {
  prefix: "tenders",
  responses: "/tenders/responses",
  tendersAdd: "/tenders/add?utm_medium=tender_search",
};

export const localeCookieName = "uicult2";

export const availableLocales = Object.values(availableLocalesEnum);

export const geoTypes = {
  countries: 0,
  regions: 1,
  cities: 2,
};

export const enum ErrorCodes {
  NOT_FOUND = "notfound",
  INVALID_INPUT_DATA = "invalid_input_data"
}

export const availableCountPerPage = [10, 20];
export const defaultPerPage = 10;

export const commonBoard = {
  id: "a0a0a0a0a0a0a0a0a0a0a0a0",
  bgColor: "var(--glz-color-neutral-tone-4)",
  color: "var(--glz-color-neutral-tone-0)",
  name: "Общая",
  shortName: "Общая",
  isCommon: true,
  availableForContact: true,
};

export const tenderLicense = 76;
export const atiWithoutAdsLicense = 563;

// TODO: Вытаскивать из swagger, после того, как back добавит описание
export const enum TenderStatuses {
  reporting = 1,
  active = 2,
  finished = 3,
  abandoned = 4,
  preModeration = 5,
  moderation = 6,
  rejected = 7,
  removed = 8,
}

export const commonCargoTypes = {
  ru: {
      0: "Штучные",
      1: "Наливные",
      2: "Сыпучие",
      3: "Скоропортящиеся",
      4: "Негабаритные",
      5: "Порошкообразные",
      6: "Опасные",
  },
  en: {
      0: "Piece goods",
      1: "Liquid bulk",
      2: "Dry bulk",
      3: "Perishable",
      4: "Oversized",
      5: "Dry (powdery) bulk",
      6: "Dangerous",
  },
};

export const pointTypeIds = {
  countries: 0,
  regions: 1,
  cities: 2,
  cargoTypes: 3,
  carTypes: 4,
  commonCargoTypes: 5,
  cityCommonCargos: 6,
};

export const topCategoriesIds = {
  1: "regions",
  2: "cities",
  3: "cargoTypes",
  4: "carTypes",
  5: "commonCargoTypes",
};

export const pageTypesEnum = {
  regions: "regions",
  cities: "cities",
  cargoTypes: "cargoTypes",
  carTypes: "carTypes",
  commonCargoTypes: "commonCargoTypes",
  cityCommonCargos: "cityCommonCargos",
};

export const commonCargoTypesEng = {
  0: "shtuchnie",
  1: "nalivnie",
  2: "sipuchie",
  3: "skoroportiashiesya",
  4: "negabaritnie",
  5: "poroshroobraznie",
  6: "opasnie",
};

export const topCategoriesNames = {
  ru: {
      1: "По регионам",
      2: "По городам",
      3: "По предмету тендера",
      4: "По типу кузова",
      5: "По типу груза",
  },
  en: {
      1: "By region",
      2: "By city",
      3: "By tender subject",
      4: "By body type",
      5: "By type of goods",
  },
};

export enum TopCategoriesTypes {
  topRegions = 1,
  topCities = 2,
  topCargoTypes = 3,
  topCarTypes = 4,
}

export const tenderFilterCountries = {
  russia: {
      from: {
          type: 0,
          id: 1,
      },
      to: {
          type: 0,
          id: 1,
      },
  },
  belarus: {
      from: {
          type: 0,
          id: 3,
      },
      to: {
          type: 0,
          id: 3,
      },
  },
  international: {
      ignore_from: [
          {
              type: 0,
              id: 1,
          },
          {
              type: 0,
              id: 3,
          },
      ],
      ignore_to: [
          {
              type: 0,
              id: 1,
          },
          {
              type: 0,
              id: 3,
          },
      ],
  },
};

export const filterTypes = {
  regions: "1",
  cities: "2",
  cargoTypes: "3",
  carTypes: "4",
  commonCargoTypes: "5",
  cityCommonCargos: "6",
};

export const filterTypeIds = {
  1: "regions",
  2: "cities",
  3: "cargoTypes",
  4: "carTypes",
  5: "commonCargoTypes",
  6: "cityCommonCargos",
};

export const commonCargoTypesIds = {
  0: [
      84,
      212,
      216,
      207,
      17,
      213,
      217,
      208,
      215,
      214,
      218,
      111,
      1,
      98,
      114,
      4,
      5,
      82,
      87,
      97,
      68,
      101,
      83,
      102,
      80,
      60,
      95,
      9,
      90,
      10,
      11,
      36,
      105,
      78,
      13,
      62,
      14,
      209,
      15,
      16,
      89,
      64,
      18,
      19,
      66,
      27,
      91,
      28,
      103,
      30,
      31,
      67,
      32,
      81,
      33,
      211,
      73,
      206,
      38,
      39,
      40,
      210,
      41,
      70,
      100,
      43,
      44,
      45,
      46,
      61,
      65,
      88,
      55,
      785,
      74,
      56,
      94,
      57,
  ],
  1: [2, 3, 26, 75, 54],
  2: [96, 8, 92, 85, 108, 109, 107, 47, 110],
  3: [6, 77, 20, 24, 25, 29, 34, 37, 52, 93, 58],
  4: [7, 113, 21, 22, 86, 42, 50, 63],
  5: [23, 71, 76],
  6: [51, 53],
};
