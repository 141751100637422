import { action, makeObservable, observable, override } from "mobx";
import FormBaseClass from "../FormBaseClass";
import { statusOptions, viewsOptions } from "../../strings";

class FormExtra extends FormBaseClass {
  constructor(rootStore) {
    super();
    this.rootStore = rootStore;
    this.locale = this.rootStore.localeModule.locale;

    this.statusOptions = statusOptions[this.locale];

    this.viewsOptions = viewsOptions[this.locale];

    this.defaultValue = {
      status: { select: this.statusOptions[2] },
      views: { select: this.viewsOptions[0] },
      tenderId: { input: "" },
    };
    this.values = this.defaultValue;

    makeObservable(this, {
      setValue: override,
      setValuesCounter: override,
      values: observable,
      validateField: action,
      onChangeTenderID: action,
      getServerData: action,
      getQueryData: action,
      setQueryData: action,
    });
  }

  validateField = (value) => {
    const pattern = /^[1-9][0-9]{0,8}$/g;
    if (value === "") return true;
    return pattern.test(value);
  };

  onChangeTenderID = (value) => {
    if (this.validateField(value)) {
      this.setValue("tenderId", "input", value);
      this.setValuesCounter();
    }
  };

  getServerData = () => {
    const { status, tenderId } = this.values;
    const result = {};

    if (tenderId.input) result.tender_id = tenderId.input;
    if (status.select.value && status.select.value !== "1,2") result.tender_statuses = status.select?.value?.split(",");

    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    return serverData;
  };

  setQueryData = (data) => {
    this.values.status.select = data.tender_statuses
      ? this.statusOptions.find((item) => item.value === data.tender_statuses.join(","))
      : this.statusOptions[2];

    this.values.tenderId.input = data.tender_id || "";
  };
}

export default FormExtra;
