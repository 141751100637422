import { makeAutoObservable } from "mobx";
import FormDirection from "./FormDirection";
import FormCarParams from "./FormCarParams";
import FormCarTypes from "./FormCarTypes";
import FormCargoParams from "./FormCargoParams";
import FormPayment from "./FormPayment";
import FormBoards from "./FormBoards";
import FormExtra from "./FormExtra";
import FormOutput from "./FormOutput";
import FormFirms from "./FormFirms";

export default class Forms {
  constructor(rootStore) {
    this.direction = new FormDirection(rootStore);
    this.carParams = new FormCarParams(rootStore);
    this.carTypes = new FormCarTypes(rootStore);
    this.cargoParams = new FormCargoParams(rootStore);
    this.payment = new FormPayment(rootStore);
    this.boards = new FormBoards(rootStore);
    this.extra = new FormExtra(rootStore);
    this.output = new FormOutput(rootStore);
    this.firms = new FormFirms(rootStore);
    makeAutoObservable(this);
  }
}
