import { action, makeObservable, observable, override } from "mobx";
import FormBaseClass from "../FormBaseClass";
import { validateField } from "./helpers";

class FormCarParams extends FormBaseClass {
  constructor(rootStore) {
    super();
    this.rootStore = rootStore;
    this.defaultValue = {
      carrying: { from: "", to: "" },
      volume: { from: "", to: "" },
    };
    this.values = this.defaultValue;

    makeObservable(this, {
      setValue: override,
      reverseFields: override,
      values: observable,
      getServerData: action,
      onChangeFieldCarParams: action,
      getQueryData: action,
      setQueryData: action,
    });
  }

  onChangeFieldCarParams = (group, field, value) => {
    const isValid = validateField(value);
    if (isValid) {
      this.setValue(group, field, value);
    }
  };

  onBlurField = (group) => {
    this.reverseFields(group);
  };

  getServerData = () => {
    const { carrying, volume } = this.values;
    const result = {};

    if (carrying.from) result.truck_loading_capacity_from = +carrying.from.replace(",", ".");
    if (carrying.to) result.truck_loading_capacity_to = +carrying.to.replace(",", ".");
    if (volume.from) result.truck_volume_from = +volume.from.replace(",", ".");
    if (volume.to) result.truck_volume_to = +volume.to.replace(",", ".");

    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    return serverData;
  };

  setQueryData = (data) => {
    const {
      truck_loading_capacity_from,
      truck_loading_capacity_to,
      truck_volume_from,
      truck_volume_to,
    } = data;
    this.values.carrying.from = truck_loading_capacity_from ? `${truck_loading_capacity_from}` : "";
    this.values.carrying.to = truck_loading_capacity_to ? `${truck_loading_capacity_to}` : "";
    this.values.volume.from = truck_volume_from ? `${truck_volume_from}` : "";
    this.values.volume.to = truck_volume_to ? `${truck_volume_to}` : "";
  };
}

export default FormCarParams;
