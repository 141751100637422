import { parsePrice } from "../../../utils/priceFormat";

export function prepareCurrencyOptions(options) {
  const result = options.map((option) => ({
    label: option.Name,
    value: option.Id,
    countryId: option.CountryIds,
  }));

  return result;
}

export function prepareServerData(form) {
  const { paymentGroup, delay, bet } = form.values;
  const parsedPrice = parsePrice(bet.from);
  const result = {};

  if (paymentGroup.withNDS) result.cashless_with_vat = paymentGroup.withNDS;
  if (paymentGroup.withoutNDS) result.cashless_without_vat = paymentGroup.withNDS;
  if (delay.from) result.deferment_payment_from = +delay.from.replace(",", ".");
  if (delay.to) result.deferment_payment_to = +delay.to.replace(",", ".");
  if (parsedPrice) {
    result.rate_from = +parsedPrice;
    result.currency_id = bet.currency.value;
    result.rate_volume_unit_id = bet.betType.value;
  }
  if (parsedPrice && bet.prepaid) result.with_prepayment = bet.prepaid;

  return result;
}
