import { reaction, makeAutoObservable } from "mobx";
import api from "../../api/api";
import { snakeToCamelObj } from "../../utils/objectHelpers";
import moveToElement from "../../utils/moveToElement";
import addMetric from "../../utils/addMetric";
import { availableCountPerPage, defaultPerPage } from "../../const";
import { formResolutions } from "./helpers";
import {
  prepareBoards,
  prepareDictionariesForCards,
  prepareDictionaries,
  prepareBoardsForCards,
  getTenderCities,
  getTenderContacts,
} from "../../api/middleware";
import { errorMessage, resultListErrors } from "../strings";
import { NOT_AVAILABLE_FOR_CONTACT } from "../../const/errorTypes";
import { isClient } from "../../utils/isClient";

const updateContactStatusTimeout = 120000;
const orderByDate = 0;

class Global {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.showClearFilterButton;
    this.queryParams = {};
    this.locale = this.rootStore.localeModule.locale;
    makeAutoObservable(this);

    reaction(
      () => this.rootStore.mediaModule.device,
      () => this.closeAllForms(),
    );

    reaction(
      () => this.dictionariesLoaded,
      (event) => {
        if (event) this.handleEnter();
      },
    );
  }

  init = (initialData) => {
    this.setContactId(initialData?.contactId);
    this.setAccount(initialData?.account);
    this.setActiveLicenses(initialData?.activeLicenses);
    this.setTenderLicense(initialData?.hasTenderLicense);
    this.getPerPageFromLocalStorage();
    this.setIsAtiApp(initialData?.isAtiApp);
    this.setHasAtiWithoutAdsLicence(initialData?.hasAtiWithoutAdsLicence);
    this.initionalRequest();
  };

  setAccount = (account) => {
    this.account = account;
    if (account) {
      this.account = { ...this.account, contactId: this.contactId };
      this.isAuthorized = true;
    }
  };

  setTenderLicense = (hasTenderLicense) => {
    this.hasTenderLicense = hasTenderLicense;
  };

  setHasAtiWithoutAdsLicence = (hasAtiWithoutAdsLicence) => {
    this.isAtiWithoutAds = hasAtiWithoutAdsLicence;
  };

  setActiveLicenses = (activeLicenses) => {
    this.activeLicenses = activeLicenses?.isPayedUser || false;
  };

  setContactId = (contactId) => {
    this.contactId = contactId;
  };

  setResult = (result) => {
    this.searchResult = result;
  };

  preparerResultListError = (message) => {
    if (message === NOT_AVAILABLE_FOR_CONTACT) {
      this.resultListError = resultListErrors[this.locale][NOT_AVAILABLE_FOR_CONTACT];
      return;
    }
    this.resultListError = "";
  };

  handleEnter = () => {
    window.addEventListener("keypress", (event) => {
      if (event.key === "Enter") {
        if (!this.searchLoading) {
          addMetric("tender-search-enter", true);
          this.onSearch();
        }
      }
    });
  };

  initionalRequest = () => {
      this.getBoardsRequest()
      .finally(() => {
        this.getDictionariesRequest()
          .then(() => {
            try {
              this.rootStore.URLQueryModule.setQueryAllForms();
            } catch (error) {
              this.preparerResultListError(error.message);
            }

            if (!this.resultListError) {
              this.onSearch({ stopMove: true, initial: true });
            }
          });
      });
  };

  account = null;

  dictionaries = {};
  dictionariesLoaded = false;
  dictionariesServerError = false;

  boards = [];
  boardsLoaded = false;

  dictionariesForCards = {};
  boardsForCards = {};

  citiesForCards = {};
  regionsForCards = {};
  countriesForCards = {};

  tenderContacts = [];
  filterForCards = {};
  tenderCounters = {};

  resultListError = false;
  serverDataResult = {};
  searchResult = {};
  profile = {};
  isAuthorized = false;
  hasTenderLicense = false;
  isAtiWithoutAds = false;
  searchLoading = false;
  isInitialRequest = true;
  activeLicenses = false;

  pageTake = defaultPerPage;
  currentPage = 1;
  contactId;

  showForms = {
    carTypes: false,
    cargoParams: false,
    payment: false,
    boards: false,
    firms: false,
    extra: false,
  };

  showAdditionParams = false;

  isAtiApp = false;

  setIsAtiApp = (atiApp) => {
    this.isAtiApp = atiApp;
  };

  get totalPages() {
    if (this.searchResult.count) {
      return Math.ceil(this.searchResult.count / this.pageTake);
    }

    return 0;
  }

  get touchedFormsCounter() {
    const formKeys = Object.keys(this.rootStore.formsModule);
    let counter = 0;
    formKeys.forEach((key) => {
      if (key === "direction" || key === "carParams") {
        return;
      }
      counter += this.rootStore.formsModule[key].valuesCounter;
    });
    return counter;
  }

  getPerPageFromLocalStorage = () => {
    if (isClient && localStorage) {
      const value = localStorage.getItem("tendersSearchPerPage");
      if (value) {
        const count = Number.parseInt(value, 10);
        if (availableCountPerPage.includes(count)) {
          this.pageTake = count;
        } else {
          this.setPageTake(defaultPerPage);
        }
      } else {
        this.setPageTake(defaultPerPage);
      }
    }
  };

  setPerPageToLocalStorage = (value) => {
    if (isClient && localStorage) {
      localStorage.setItem("tendersSearchPerPage", value);
    }
  };

  setPageTake = (value) => {
    this.pageTake = value;
    this.setPerPageToLocalStorage(value);
  };

  setCurrentPage = (value) => {
    this.currentPage = value;
  };

  setShowAdditionParams = () => {
    this.showAdditionParams = !this.showAdditionParams;
  };

  setShowForms = (form) => {
    this.showForms[form] = !this.showForms[form];
  };

  getDictionariesRequest = () => {
    this.dictionariesLoaded = false;
    this.dictionariesServerError = false;
    const apiPromise = api.getDictionaries();
    apiPromise
      .then(({ data }) => {
        this.dictionaries = prepareDictionaries(data);
        this.dictionariesLoaded = true;
        this.dictionariesServerError = false;
        this.dictionariesForCards = prepareDictionariesForCards(data);
      })
      .catch((err) => {
        console.error(err);
        this.dictionariesLoaded = true;
        this.dictionariesServerError = true;
        this.rootStore.notificationModule.setNotification({
          type: "error",
          message: errorMessage[this.locale].loadingError,
        });
      });
    return apiPromise;
  };

  getBoardsRequest = () => {
    this.boardsLoaded = false;
    this.boardServerError = false;
    return api.getBoards()
      .then(({ data }) => {
        this.boardsLoaded = true;
        this.boardServerError = false;
        this.boards = prepareBoards(data);
        this.boardsForCards = prepareBoardsForCards(data);
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          this.boardServerError = true;
        }
        this.boardsLoaded = false;
      });
  };

  closeAllForms = () => {
    this.showForms = Object.keys(this.showForms).reduce((acc, item) => {
      acc[item] = false;
      return acc;
    }, { ...this.showForms });
  };

  onChangeVisibleForm = (formName) => {
    const { device } = this.rootStore.mediaModule;
    const result = formResolutions[formName][device].reduce((acc, item) => {
      acc[item] = !acc[item];
      return acc;
    }, { ...this.showForms });

    this.showForms = result;
  };

  get showClearFilterButton() {
    // TODO: полностью перенести сортировку тендеров из формы
    if (this.rootStore.formsModule) {
      const tempForms = { ...this.rootStore.formsModule };
      delete tempForms.output;
      return Object.values(tempForms).some((form) => form.isNotEmpty);
    }
    return false;
  }

  clearFilter = () => {
    // TODO: полностью перенести сортировку тендеров из формы
    const tempForms = { ...this.rootStore.formsModule };
    delete tempForms.output;
    Object.values(tempForms).forEach((form) => {
      if (form.clearForm) {
        form.clearForm();
      }
    });
    this.rootStore.URLQueryModule.clearQueryFilterParamsToUrl();
  };

  getAllFormsResult = () => {
    const { currentSource } = this.rootStore.sourceButtonsModule;
    const result = Object.values(this.rootStore.formsModule).reduce((acc, form) => {
      const serverData = form.getServerData();
      acc = { ...acc, ...serverData };
      return acc;
    }, {});

    if (currentSource !== null) {
      result.tender_type = currentSource;
    }

    return result;
  };

  onSearch = async (params = {}) => {
    this.searchLoading = true;
    this.resultListError = "";
    const resultFromAllForms = this.getAllFormsResult();

    if (params.page) {
      this.setCurrentPage(params.page);
    } else {
      this.setCurrentPage(1);
    }

    if (params.reverse && (resultFromAllForms.from || resultFromAllForms.to)) {
      const temp = { ...resultFromAllForms.from };
      resultFromAllForms.from = resultFromAllForms.to;
      resultFromAllForms.to = temp;
    }

    if (!resultFromAllForms.tender_statuses) {
      resultFromAllForms.tender_statuses = ["1", "2"];
    }

    if (resultFromAllForms?.tender_statuses?.find((item) => item === "0")) {
      resultFromAllForms.tender_statuses = null;
    }

    if (!params.initial && !resultFromAllForms.order_by) {
      resultFromAllForms.order_by = orderByDate;
    }

    this.serverDataResult = resultFromAllForms;
    this.serverDataResult.take = this.pageTake;
    this.serverDataResult.skip = (this.currentPage - 1) * this.pageTake;
    if (params.initial) {
      this.serverDataResult.order_by = 0;
    }
    if (this.rootStore.URLQueryModule) {
      this.rootStore.URLQueryModule.setQueryFilterParamsIntoUrl();
    }
    api.getTenders(this.serverDataResult, this.isAuthorized)
      .then(async ({ data }) => {
        const result = snakeToCamelObj(data);
        const body = getTenderCities(result.tenders);
        this.tenderContacts = getTenderContacts(result.tenders);
        if (data.count) {
          const geoResult = await this.rootStore.geoModule.fetchGeo(body.cities, body.regions, body.countries);
          this.citiesForCards = geoResult.cities;
          this.regionsForCards = geoResult.regions;
          this.countriesForCards = geoResult.countries;
          this.filterForCards = snakeToCamelObj(this.serverDataResult);
          this.setResult(result);
          this.isInitialRequest = params.initial && !this.showClearFilterButton;
          (this.isAuthorized, "isAuthorized");
          if (this.isAuthorized && this.tenderContacts.length) {
            this.getContactStatusRequest(this.tenderContacts);
          }
        } else {
          this.setResult(result);
        }
        if (!params.stopMove) {
          setTimeout(() => {
            moveToElement("tenders-result-list");
          }, 300);
        }
      })
      .catch((err) => {
        console.error(err);
        this.rootStore.notificationModule.setNotification({
          type: "error",
          message: errorMessage[this.locale].somethingWrong,
        });
      })
      .finally(() => {
        this.searchLoading = false;
      });
  };

  onChangePageTake = (event) => {
    this.setPageTake(event.value);
    this.onSearch({ initial: this.isInitialRequest });
  };

  onChangeCurrentPage = (event) => {
    this.onSearch({ page: event.selected, initial: this.isInitialRequest });
  };

  getContactStatusRequest = (body) => {
    if (!body.length) return;
    isClient && api.getContactStatus(body)
      .then(({ data }) => {
        this.tenderContactsStatuses = data;
        // TODO: Подумать над повторным запросом статусов,
        // если первый выполнился с ошибкой
        setTimeout(() => {
          this.getContactStatusRequest(this.tenderContacts);
        }, updateContactStatusTimeout);
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export default Global;
