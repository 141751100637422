import { debounce } from "throttle-debounce";
import { action, makeObservable, observable, override } from "mobx";
import FormBaseClass from "../FormBaseClass";
import api from "../../../api";
import { errorMessage } from "../../strings";
import { geoLanguageEnum } from "../../../const";

class FormDirection extends FormBaseClass {
  constructor(rootStore) {
    super();

    this.rootStore = rootStore;
    this.defaultValue = { direction: { from: "", to: "" } };
    this.values = this.defaultValue;
    this.locale = this.rootStore.localeModule.locale;

    makeObservable(this, {
      setValue: override,
      values: observable,
      suggestion: observable,
      selectedSuggests: observable,
      onChangeFieldDirection: action,
      setSuggestion: action,
      setSelectedSuggests: action,
      reverseFieldsDirection: action,
      clearForm: action,
      handleSuggestRequest: action,
      handleSuggest: action,
      onBlurField: action,
      getServerData: action,
      getQueryData: action,
      setQueryData: action,
    });
  }

  suggestion = [];
  selectedSuggests = {
    from: {},
    to: {},
  };

  setSuggestion = (value) => {
    this.suggestion = value;
  };

  onChangeFieldDirection = (group, field, value) => {
    this.setValue(group, field, value);
    this.setSelectedSuggests(field, {});
  };

  setSelectedSuggests = (type, value) => {
    this.selectedSuggests[type] = value;
  };

  reverseFieldsDirection = () => {
    this.values = {
      direction: {
        from: this.values.direction.to,
        to: this.values.direction.from,
      },
    };
    this.selectedSuggests = {
      from: this.selectedSuggests.to,
      to: this.selectedSuggests.from,
    };
  };

  clearForm = () => {
    this.values = this.defaultValue;
    this.selectedSuggests = {
      from: {},
      to: {},
    };
  };

  handleSuggestRequest = (type) => {
    api.getCities({ prefix: this.values.direction[type], geo_types: 7, language: geoLanguageEnum[this.locale] })
      .then(({ data }) => {
        this.setSuggestion(data);
      })
      .catch((error) => {
        console.error(error);
        this.rootStore.notificationModule.setNotification({
          type: "error",
          message: errorMessage[this.locale].searchError,
        });
      });
  };

  debounsedRequest = debounce(300, false, (type) => {
    this.handleSuggestRequest(type);
  });

  handleSuggest = (type) => {
    this.debounsedRequest(type);
  };

  onBlurField = () => {
    this.setSuggestion([]);
  };

  getServerData = () => {
    const { from, to } = this.selectedSuggests;
    const result = {};
    if (from.id) result.from = { type: from.type, id: +from.id };

    if (to.id) result.to = { type: to.type, id: +to.id };

    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    if (Object.keys(serverData).length) {
      serverData.from_text = this.values.direction.from;
      serverData.to_text = this.values.direction.to;
    }
    return serverData;
  };

  setQueryData = (data) => {
    this.selectedSuggests.from = data.from || {};
    this.selectedSuggests.to = data.to || {};
    this.values.direction.from = data.from_text || "";
    this.values.direction.to = data.to_text || "";
  };
}

export default FormDirection;
