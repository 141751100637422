const theme = {
    colors: {
      primary: "var(--glz-color-primary)",
      bostonBlue: "var(--glz-color-primary-tone-1)",
      seashell: "var(--glz-color-neutral)",
      limedSpruce: "var(--glz-color-neutral-tone-5)",
      monza: "var(--glz-color-error)",
      chablis: "var(--glz-color-error-tone-3)",
      color_bg: "var(--glz-color-neutral)",
      color_white: "var(--glz-color-neutral-tone-0)",
      color_gray: "var(--glz-color-neutral-tone-3)",
      color_black: "var(--glz-color-neutral-tone-5)",

      color_darkgray: "var(--glz-color-neutral-tone-4)",
      color_bluegray: "var(--glz-color-neutral-tone-4)",
      color_dark: "var(--glz-color-neutral-tone-5-rgb)",

      color_blue: "var(--glz-color-primary)",
      color_blue_hover: "var(--glz-color-primary-tone-1)",
      color_blue_pressed: "var(--glz-color-primary-tone-2)",

      color_orange: "var(--glz-color-warning)",
      color_orange_hover: "var(--glz-color-warning-tone-1)",
      color_orange_pressed: "var(--glz-color-warning-tone-2)",

      color_green: "var(--glz-color-success)",
      color_green_hover: "var(--glz-color-success-tone-1)",
      color_green_pressed: "var(--glz-color-success-tone-2)",
      color_yellow_green: "var(--glz-color-success-tone-5)",
      color_rice_flower: "var(--glz-color-success-tone-3)",
      cream_can: "var(--glz-color-warning-tone-5)",

      color_red: "var(--glz-color-error)",

      color_disabled: "var(--glz-color-neutral-tone-3)",
      color_gray_light: "var(--glz-color-neutral-tone-2)",

      color_error_bg: "var(--glz-color-error-tone-3)",
      color_error: "var(--glz-color-error)",

      color_input_text: "var(--glz-color-neutral-tone-5)",
      color_input_placeholder: "var(--glz-color-neutral-tone-3)",

      color_button_disabled: "var(--glz-color-neutral-tone-2)",
      color_button_disabled_font: "var(--glz-color-neutral-tone-3)",

      footer_headings: "var(--glz-color-neutral-tone-4)",
      filter_color: "var(--glz-color-neutral-tone-1)",
      filter_tab_link: "var(--glz-color-primary-tone-3)",
      filter_line: "var(--glz-color-neutral-tone-3)",
      youtube_color: "var(--glz-color-error)",
      warning_message: "var(--glz-color-warning-tone-1)",

      result_heading_border: "var(--glz-color-neutral-tone-3)",
    },
    font_verdana: "Verdana, 'Dejavu Sans', Geneva, Tahoma, sans_serif",
    fontSize: {
      xl: "",
      lg: "",
      md: "",
      nm: "14px",
      sm: "",
    },
  };

  export default theme;
