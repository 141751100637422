import { makeAutoObservable } from "mobx";

class Notification {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  notification = {};

  removeNotification = () => {
    this.notification = {};
  };

  setNotification = ({ message, type }) => {
    this.notification = { show: true, message, type };
  };
}

export default Notification;
