/** Адаптеры для гео-сервиса */
/** Их нужно будет убрать, когда Mercury полностью перейдет на гео-сервис */

import { Suggestion, SuggestionsRequest, SuggestionsResponse } from "../api-types/swagger/GeoTypes";
import { GeoAutocompleteListItem, SearchGeoFilter } from "../api-types/swagger/Sphinx";
import { GeoType } from "../api-types/swagger/TenderTypes";
import { geoTypes } from "../const";

export type SuggestionTypes = 1 | 2 | 4 | 8

export const prepareGeoTypeIntoOldFormat = (geoType: any) => {
    switch (geoType) {
        case 1:
            return geoTypes.cities;
        case 2:
            return geoTypes.regions;
        case 4:
            return geoTypes.countries;
        default:
            return 0;
    }
};

export const prepareGeoRequest = (data: SearchGeoFilter): SuggestionsRequest => ({
    prefix: data.prefix || "",
    suggestionTypes: data.geoTypes as SuggestionTypes,
});

export const prepareResponseGeoItem = (suggestion: Suggestion): GeoAutocompleteListItem => ({
    text: suggestion.address,
    id: `${suggestion.city?.id || suggestion.region?.id || suggestion.country?.id}`,
    regionId: suggestion?.region?.id || null,
    countryId: suggestion?.country?.id,
    type: prepareGeoTypeIntoOldFormat(suggestion.type) as GeoType,
});

export const prepareResponse = (response: SuggestionsResponse): GeoAutocompleteListItem[] => {
    const result: GeoAutocompleteListItem[] = [];

    response.suggestions?.forEach((item) => {
        result.push(prepareResponseGeoItem(item));
    });

    return result;
};
