import en from "./strings.en";

export const ru = {
  errorMessageTitle: "Обновите страницу",
  errorMessageText: "Не удалось загрузить страницу. Попробуйте обновить её. Если ошибка повторится, попробойте подождать пару минут и повторить.",
  reloadPage: "Обновить страницу",
} as const;

const strings = {
  ru,
  en: { ...ru, ...en },
};

export default strings;
