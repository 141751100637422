/* eslint-disable prefer-destructuring */
import { computed, autorun, reaction, action, makeObservable, observable, override } from "mobx";
import { debounce } from "throttle-debounce";
import { useEffect } from "react";
import FormBaseClass from "../FormBaseClass";
import { extendedSearchListener } from "./helpers";
import api from "../../../api";
import { errorMessage, pointOptions, firmListTypeOptions } from "../../strings";
import { geoLanguageEnum } from "../../../const";

class FormFirms extends FormBaseClass {
  constructor(rootStore) {
    super();

    this.rootStore = rootStore;
    this.locale = this.rootStore.localeModule.locale;

    this.pointOptions = pointOptions[this.locale];

    this.firmListTypeOptions = firmListTypeOptions[this.locale];

    this.defaultValue = {
      point: { select: this.pointOptions[0] },
      city: { autosuggest: "" },
      firm: {
        autosuggest: "",
        select: this.firmListTypeOptions[0],
        optionSelector: [],
      },
    };
    this.values = this.defaultValue;

    makeObservable(this, {
      setValue: override,
      setValuesCounter: override,
      values: observable,
      selectedFirm: observable,
      selectedCity: observable,
      showFirmsList: observable,
      firmListLoaded: observable,
      firmSuggestion: observable,
      citySuggestion: observable,
      participantError: observable,
      firmListItemOptions: observable,
      firmListServerError: observable,
      showExtendedSearchPopup: observable,
      showFirmWarning: computed,
      setParticipantError: action,
      onShowFirmsList: action,
      clearFirmList: action,
      clearFirmSuggestion: action,
      openExtendedSearchPopup: action,
      closeExtendedSearchPopup: action,
      onSuggestionFirmSelected: action,
      onSuggestionCitySelected: action,
      onChangeFirmField: action,
      onChangeCitiesField: action,
      onSelectFirmsListItem: action,
      onClearSelectedFirmLists: action,
      getFirmsRequest: action,
      getFirmsSuggestions: action,
      getCitiesRequest: action,
      getCitiesSuggestions: action,
      getListsRequest: action,
      clearForm: action,
      getServerData: action,
      getQueryData: action,
      setQueryData: action,
    });

    autorun(() => {
      if (this.values.firm.optionSelector.length) {
        this.values.firm.autosuggest = "";
        this.selectedFirm = null;
      }
    });

    reaction(
      () => this.showFirmsList,
      (value) => {
        if (value) this.getListsRequest();
      },
    );

    reaction(
      () => this.values.firm.optionSelector,
      () => this.setValuesCounter(),
    );

    reaction(
      () => this.rootStore.globalModule.activeLicenses,
      (e) => this.setParticipantError(e),
    );
  }

  participantError = true;
  showFirmsList = false;
  showExtendedSearchPopup = false;
  selectedFirm = null;
  selectedCity = null;
  firmSuggestion = [];
  citySuggestion = [];

  firmListItemOptions = [];
  firmListLoaded = false;
  firmListServerError = false;

  get showFirmWarning() {
    const { point, city } = this.values;
    const isNotDefaultPoint = point.select.value !== this.defaultValue.point.select.value;
    return this.selectedFirm?.alias_id && (isNotDefaultPoint || city.autosuggest);
  }

  setParticipantError = (value) => {
    if (value) {
      this.participantError = false;
    }
  };

  onShowFirmsList = (value) => {
    this.showFirmsList = value;
  };

  clearFirmList = () => {
    this.values.firm.optionSelector = [];
    this.values.firm.select = this.firmListTypeOptions[0];
  };

  clearFirmSuggestion = () => {
    this.firmSuggestion = [];
  };

  openExtendedSearchPopup = () => {
    this.showExtendedSearchPopup = true;
    this.clearFirmSuggestion();
    window.addEventListener("message", (event) => extendedSearchListener(event, this), false);
  };

  closeExtendedSearchPopup = () => {
    this.showExtendedSearchPopup = false;
    window.removeEventListener("message", (event) => extendedSearchListener(event, this), false);
  };

  onSuggestionFirmSelected = (event, suggestionData) => {
    this.selectedFirm = suggestionData.suggestion;
  };

  onSuggestionCitySelected = (event, suggestionData) => {
    this.selectedCity = suggestionData.suggestion;
  };

  onChangeFirmField = (value) => {
    this.selectedFirm = null;
    this.clearFirmSuggestion();
    this.setValue("firm", "autosuggest", value);
    this.setValuesCounter();
  };

  onChangeCitiesField = (value) => {
    this.selectedCity = null;
    this.citySuggestion = [];
    this.setValue("city", "autosuggest", value);
    this.setValuesCounter();
  };

  onSelectFirmsListItem = (boardId) => {
    const findedId = this.values.firm.optionSelector.find((id) => id === boardId);
    if (findedId) {
      this.values.firm.optionSelector = this.values.firm.optionSelector.filter((id) => id !== boardId);
    } else {
      this.values.firm.optionSelector = [...this.values.firm.optionSelector, boardId];
    }
  };

  onClearSelectedFirmLists = () => {
    this.values.firm.optionSelector = [];
  };

  /** Получение фирм */
  getFirmsRequest = () => {
    api.getFirms({ keywords: this.values.firm.autosuggest })
      .then((data) => {
        this.firmSuggestion = data;
      })
      .catch((err) => {
        console.error(err);
        this.rootStore.notificationModule.setNotification({
          type: "error",
          message: errorMessage[this.locale].companySearchError,
        });
      });
  };

  debounsedFirmRequest = debounce(300, false, () => {
    this.getFirmsRequest();
  });

  getFirmsSuggestions = () => {
    if (this.values.firm.autosuggest.length) {
      this.debounsedFirmRequest();
    }
  };

  /** Получение городов */
  getCitiesRequest = () => {
    api.getCities({ prefix: this.values.city.autosuggest, geo_types: 1, language: geoLanguageEnum[this.locale] })
      .then(({ data }) => {
        this.citySuggestion = data;
      })
      .catch((error) => {
        console.error(error);
        this.rootStore.notificationModule.setNotification({
          type: "error",
          message: errorMessage[this.locale].errorReceivingCities,
        });
      });
  };

  debounsedCitiesRequest = debounce(300, false, (type) => {
    this.getCitiesRequest(type);
  });

  getCitiesSuggestions = () => {
    this.debounsedCitiesRequest();
  };

  /** Получение списков фирм */
  getListsRequest = () => {
    this.firmListLoaded = false;
    this.firmListServerError = false;
    api.getLists()
      .then(({ data }) => {
        this.firmListLoaded = true;
        this.firmListServerError = false;
        this.firmListItemOptions = data;
      })
      .catch((err) => {
        console.error(err);
        this.firmListLoaded = true;
        this.firmListServerError = true;
        this.rootStore.notificationModule.setNotification({
          type: "error",
          message: "Ошибка загрузки списков фирм",
        });
      });
  };

  clearForm = () => {
    this.values = this.defaultValue;
    this.selectedFirm = null;
    this.selectedCity = null;
    this.valuesCounter = 0;
  };

  /** Подготовка данных для сервера */
  getServerData = () => {
    const { point, firm } = this.values;
    const result = {};

    if (point.select.value) result.min_firm_score = point.select.value;
    if (firm.optionSelector.length) {
      if (firm.select.value === 1) {
        result.firm_list_ids = firm.optionSelector;
      }

      if (firm.select.value === 2) {
        result.firm_list_ids_to_exclude = firm.optionSelector;
      }
    }

    if (this.selectedFirm?.alias_id) {
      result.ati_id = this.selectedFirm.alias_id;
    }

    if (this.selectedFirm?.aliasId) {
      result.ati_id = this.selectedFirm.aliasId;
    }

    if (this.selectedCity?.id) {
      result.firm_geo = { type: this.selectedCity.type, id: this.selectedCity.id };
    }

    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    if (Object.keys(serverData)) {
      if (this.values.city.autosuggest) {
        serverData.firm_geo_text = this.values.city.autosuggest;
      }
      if (this.values.firm.autosuggest) {
        serverData.firm_name = this.values.firm.autosuggest;
      }
    }
    return serverData;
  };

  setQueryData = (data) => {
    if (this.participantError) return;
    this.values.point.select = this.pointOptions.find((item) => item.value === data.min_firm_score) || this.pointOptions[0];
    if (data.firm_list_ids_to_exclude) {
      this.values.firm.select = this.firmListTypeOptions[1];
      this.values.firm.optionSelector = data.firm_list_ids_to_exclude || [];
    }

    if (data.firm_geo) {
      this.selectedCity = data.firm_geo;
    }

    if (data.ati_id) {
      this.selectedFirm = {
        aliasId: data.ati_id,
      };
    }

    if (data.firm_list_ids) {
      this.values.firm.select = this.firmListTypeOptions[0];
      this.values.firm.optionSelector = data.firm_list_ids || [];
    }

    this.values.city.autosuggest = data.firm_geo_text || "";
    this.values.firm.autosuggest = data.firm_name || "";
  };
}

export default FormFirms;
