import { makeAutoObservable } from "mobx";

import addMetric from "../../utils/addMetric";

class SubscribePopup {
  constructor() {
    makeAutoObservable(this);
  }
  visibleSubscribePopup = false;

  showSubscribePopup = () => {
    this.visibleSubscribePopup = true;
    addMetric("tender-open-subscribe");
  };

  hideSubscribePopup = () => {
    this.visibleSubscribePopup = false;
  };
}

export default SubscribePopup;
