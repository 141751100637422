import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(6, 21, 35, 0.8);

  @keyframes show {
    0% {
      bottom: -200px;
    }

    100% {
      bottom: 0;
    }
  }
`;

export const AndroidInformer = styled.div`
  background: var(--glz-color-neutral-tone-0);
  position: relative;
  padding: 16px;
  padding-right: 60px;
  max-width: 772px;
  width: 100%;
  display: flex;
  align-items: center;
  bottom: -200px;
  animation: show 200ms ease-in-out 0.5s normal forwards;
  .ati-icon {
    margin-right: 16px;
    min-width: 32px;
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    flex: 1;
    margin-right: 16px;
  }
  @media (max-width: 570px) {
    flex-flow: row wrap;
    padding-right: 16px;
    .content {
      margin-bottom: 8px;
    }
    .title {
      font-size: 12px;
      line-height: 16px;
    }
    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;
    }
  }
`;

export const IphoneInformer = styled.div`
  background: var(--glz-color-neutral-tone-0);
  position: relative;
  border-radius: 4px;
  padding: 16px;
  padding-right: 50px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  max-width: 772px;
  width: 100%;
  bottom: -200px;
  animation: show 200ms ease-in-out 0.5s normal forwards;
  @media (max-width: 700px) {
    padding-right: 16px;
  }
  .ati-icon {
    margin-right: 16px;
    min-width: 32px;
  }
  .share-icon {
    margin-left: 3px;
    margin-right: 3px;
  }
`;

export const InforemrContent = styled.div`
  display: flex;
  align-items: center;
`;

export const InforemrTitle = styled.div`
  font-size: 19px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: var(--glz-color-neutral-tone-5);
  &.mobile {
    display: none;
    margin-bottom: 8px;
    padding-right: 20px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 20px;
    &.tablet {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`;

export const InforemrDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: var(--glz-color-neutral-tone-5);
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  @media (max-width: 700px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:before,
  &:after {
    position: absolute;
    content: "";
    background-color: var(--glz-color-neutral-tone-4);
    height: 2px;
    width: 18px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  @media (max-width: 700px) {
    top: 0px;
    right: 0px;
    transform: none;
  }
`;
