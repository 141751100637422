import { action, makeObservable, observable, computed } from "mobx";
import BigNumber from "bigint-polyfill";
import { getArrayFromBitsum } from "../../../utils/bits";
import FormBaseClass from "../FormBaseClass";

class FormCarTypes extends FormBaseClass {
  constructor(rootStore) {
    super();
    this.rootStore = rootStore;
    this.defaultValue = {
      carTypes: { optionSelector: [] },
    };
    this.values = this.defaultValue;
    makeObservable(this, {
      values: observable,
      showClearButton: computed,
      getServerData: action,
      getQueryData: action,
      setQueryData: action,
    });
  }

  get showClearButton() {
    return Boolean(this.values.carTypes.optionSelector.length);
  }

  getServerData = () => {
    const { optionSelector } = this.values.carTypes;
    const result = {};
    const bitSum = optionSelector.reduce((acc, bit) => acc.or(bit), new BigNumber(0));

    if (optionSelector.length) result.car_types = bitSum.toString();

    return result;
  };

  getQueryData = () => {
    const serverData = this.getServerData();
    return serverData;
  };

  setQueryData = (data) => {
    if (data.car_types) {
      const optionIds = getArrayFromBitsum(data.car_types, this.rootStore.globalModule.dictionaries.CarTypes);
      this.values.carTypes.optionSelector = optionIds.values;
    }
  };
}

export default FormCarTypes;
