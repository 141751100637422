import { makeAutoObservable } from "mobx";

class LoginPopup {
  constructor() {
    makeAutoObservable(this);
  }
   visibleLoginPopup = false;

   showLoginPopup = () => {
    this.visibleLoginPopup = true;
  };

   hideLoginPopup = () => {
    this.visibleLoginPopup = false;
  };
}

export default LoginPopup;
