/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import { makeAutoObservable, reaction } from "mobx";
import api from "../../api";
import addMetric from "../../utils/addMetric";
import i18nStrings from "./strings";
import {
  generateSendSubscribePayload,
  onSendSubscribeErrorHandler,
  validationEmailErrorHandler,
} from "./helpers";
import getCurrentGeoType from "../../utils/getCurrentGeoType";
import { tendersTabs, geoTypes } from "../../const";

class FilterSubscribe {
  constructor(rootStore, locale) {
    this.rootStore = rootStore;
    this.geoData = {};
    this.locale = locale;
    this.strings = i18nStrings[this.locale];
    makeAutoObservable(this);

    reaction(
      () => this.rootStore.globalModule.account,
      (event) => {
        this.getCurrentContact(event);
      },
    );

    reaction(
      () => this.rootStore.formsModule.direction.selectedSuggests.from,
      (event) => {
        this.directionFrom = event;
      },
    );

    reaction(
      () => this.rootStore.formsModule.direction.selectedSuggests.to,
      (event) => {
        this.directionTo = event;
      },
    );

    this.getFilterSubscriptionsRequest();
  }

   showFilterSubscribePopup = false;
   showFilterSubscribePage = false;
   email = "";
   emailErrorMessage = "";
   popupLoading = false;
   subscriptionsLoaded = false;
   subscriptions = [];
   directionFrom = {};
   directionTo = {};
   fromMatchDirection = {};
   toMatchDirection = {};
   loadingGeoData = false;

  validateEmail = () => {
    if (!this.email) {
      this.emailErrorMessage = this.strings.emailEmptyValidate;
      return false;
    }

    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Zа-яА-ЯёЁ\-0-9]+\.)+[a-zA-Zа-яА-ЯёЁ]{2,}))$/;

    if (!pattern.test(this.email)) {
      this.emailErrorMessage = this.strings.emailNotValid;
      return false;
    }
    this.emailErrorMessage = "";
    return true;
  };

  getCurrentContact = (accountData) => {
    if (accountData) {
      const { contactId } = accountData;
      const currentContact = accountData.contacts.find((contact) => contact.id === contactId);
      this.email = currentContact ? currentContact.email : "";
    }
  };

  getFilterSubscriptionsRequest = () => {
    api.getFilterSubscriptions()
      .then(({ data }) => {
        this.subscriptionsLoaded = true;
        this.subscriptions = data;
      })
      .catch(() => {
        this.subscriptionsLoaded = true;
      });
  };

  getGeoRequest = async () => {
    this.loadingGeoData = true;
    try {
      const geoBody = this.subscriptions.reduce((acc, subscription) => {
        subscription.routes.forEach((route) => {
          const currentGeoType = getCurrentGeoType(route.geoType);
          if (route.geoType === geoTypes[currentGeoType]) {
            if (route.geoId && !acc[currentGeoType].includes(route.geoId)) {
              acc[currentGeoType].push(route.geoId);
            }
          }
        });
        return acc;
      }, { countries: [], regions: [], cities: [] });
      const resultGeo = await this.rootStore.geoModule.fetchGeo(geoBody.cities, geoBody.regions, geoBody.countries);
      this.geoData = resultGeo;
      this.loadingGeoData = false;
    } catch (error) {
      console.error(error);
      this.loadingGeoData = false;
      this.rootStore.notificationModule.setNotification({
        type: "error",
        message: this.strings.somethingWrong,
      });
    }
  };

   get showFilterInfo() {
    const isDirectionFrom = Object.values(this.directionFrom).length;
    const isDirectionTo = Object.values(this.directionTo).length;

    return (isDirectionFrom || isDirectionTo) && !this.subscriptions.length;
  }

   get showFilterSubscribeLink() {
    const isDirectionFrom = Object.values(this.directionFrom).length;
    const isDirectionTo = Object.values(this.directionTo).length;

    return (isDirectionFrom || isDirectionTo);
  }

  getCurrentSubscription = () => {
    const subscriptionsHashs = this.subscriptions.map((subscription) => ({
      ...subscription,
      hash: subscription.routes.slice().sort((a, b) => a.direction - b.direction)
        .reduce((acc, route) => {
          acc.push(`${route.direction}/${route.geoType}/${route.geoId}`);
          return acc;
        }, [])
        .filter(Boolean)
        .join("-"),
    }));

    const directionFromHash = Object.keys(this.directionFrom).length ? `${0}/${this.directionFrom.type}/${this.directionFrom.id}` : "";
    const directionToHash = Object.keys(this.directionTo).length ? `${1}/${this.directionTo.type}/${this.directionTo.id}` : "";
    const suggestionHash = [directionFromHash, directionToHash].filter(Boolean).join("-");

    return {
      subscriptionsHashs,
      suggestionHash,
    };
  };

   get showFilterCancelLink() {
    const hashes = this.getCurrentSubscription();
    const result = hashes.subscriptionsHashs.find((item) => item.hash === hashes.suggestionHash);
    return result;
  }

   setShowFilterSubscribePopup = (value) => {
    if (!this.rootStore.globalModule.account) {
      this.rootStore.loginPopupModule.showLoginPopup();
    } else {
      this.showFilterSubscribePopup = value;
      if (value) {
        addMetric("tender-subscribe-open-popup");
      }
    }
  };

  deleteFilterSubscriptionsRequest = (subscriptionId) => {
    api.deleteFilterSubscriptions(subscriptionId)
      .then(() => {
        this.subscriptions = this.subscriptions.filter((subscription) => subscription.id !== subscriptionId);
      })
      .catch((error) => {
        console.error(error);
        this.rootStore.notificationModule.setNotification({
          type: "error",
          message: this.strings.somethingWrong,
        });
      });
  };

   cancelSubscription = () => {
    const hashes = this.getCurrentSubscription();
    const currentSubscription = hashes.subscriptionsHashs.find((item) => item.hash === hashes.suggestionHash);
    addMetric("tender-subscribe-delete");
    this.deleteFilterSubscriptionsRequest(currentSubscription.id);
  };

   deleteFilterSubscriptions = (subscriptionId) => {
    addMetric("tender-subscribe-delete-on-page");
    this.deleteFilterSubscriptionsRequest(subscriptionId);
  };

   setEmail = (value) => {
    this.emailErrorMessage = "";
    this.email = value;
  };

   goToFilter = (subscription, routes) => {
    this.rootStore.tabsModule.activeTab = tendersTabs.TENDER_SEARCH_TAB;
    this.rootStore.formsModule.direction.selectedSuggests.to = {};
    this.rootStore.formsModule.direction.selectedSuggests.from = {};
    this.rootStore.formsModule.direction.values.direction.from = "";
    this.rootStore.formsModule.direction.values.direction.to = "";
    if (routes.fromRoute) {
      this.rootStore.formsModule.direction.selectedSuggests.from = {
        id: routes.fromRoute.geoId,
        type: routes.fromRoute.geoType,
      };
      this.rootStore.formsModule.direction.values.direction.from = routes.fromText;
    }
    if (routes.toRoute) {
      this.rootStore.formsModule.direction.selectedSuggests.to = {
        id: routes.toRoute.geoId,
        type: routes.toRoute.geoType,
      };
      this.rootStore.formsModule.direction.values.direction.to = routes.toText;
    }

    window.scrollTo({ top: 0 });
    this.rootStore.globalModule.onSearch();
  };

   onSendSubscribe = () => {
    if (this.validateEmail()) {
      this.popupLoading = true;
      const payload = generateSendSubscribePayload(this);

      addMetric("tender-subscribe-click-in-popup");

      api.validationEmail(this.email)
        .then(() => {
          api.sendFilterSubscriptions(payload)
            .then(({ data }) => {
              this.popupLoading = false;
              this.setShowFilterSubscribePopup(false);
              this.subscriptions.push(data);
            })
            .catch((error) => {
              this.popupLoading = false;
              onSendSubscribeErrorHandler(error.response, this);
            });
        })
        .catch((error) => {
          this.popupLoading = false;
          validationEmailErrorHandler(error.response, this);
        });
    }
  };
}

export default FilterSubscribe;
