export default function addMetric(event: any, uniq = false) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  if (uniq) {
    if (window.dataLayer.find((item) => item.event === event)) return;
    window.dataLayer.push({ event });
  } else {
    window.dataLayer.push({ event });
  }
}
