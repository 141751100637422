import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  #page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Verdana, 'Dejavu Sans', Geneva, Tahoma, sans-serif;
    color: var(--glz-color-neutral-tone-5);
  }
`;

export default GlobalStyles;
