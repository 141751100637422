export const formResolutions = {
  carTypes: {
    desktop: ["carTypes", "cargoParams", "payment", "boards"],
    tablet: ["carTypes", "cargoParams"],
    mobile: ["carTypes"],
  },
  cargoParams: {
    desktop: ["carTypes", "cargoParams", "payment", "boards"],
    tablet: ["carTypes", "cargoParams"],
    mobile: ["cargoParams"],
  },
  payment: {
    desktop: ["carTypes", "cargoParams", "payment", "boards"],
    tablet: ["payment", "boards"],
    mobile: ["payment"],
  },
  boards: {
    desktop: ["carTypes", "cargoParams", "payment", "boards"],
    tablet: ["payment", "boards"],
    mobile: ["boards"],
  },
  firms: {
    desktop: ["firms", "extra"],
    tablet: ["firms", "extra"],
    mobile: ["firms"],
  },
  extra: {
    desktop: ["firms", "extra"],
    tablet: ["firms", "extra"],
    mobile: ["extra"],
  },
};
