import en from "./strings.en";

const ru = {
  emailEmptyValidate: "Введите email",
  emailNotValid: "Это не email. Проверьте, нет ли в нём опечаток.",
  subscriptionLimit: "Превышено максимальное количество подписок",
  somethingWrong: "Перезагрузите страницу, что-то пошло не так.",

  emailRequired: "Укажите email в формате mailbox@example.com",
  emailInvalidDomain: "На введённый email нельзя доставить письма. Пожалуйста, используйте другой почтовый ящик.",
  emailForbidden: "Не используйте доменные имена нашей компании",
  emailBlacklist: "Этот email находится в чёрном списке за нарушение правил сайта. Пожалуйста, укажите другую почту.",
  emailTooLong: "Длина email не может быть больше 50 символов",

};

export default { ru, en: { ...ru, ...en } };
