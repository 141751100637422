import styled from "styled-components";

export const ErrorMessageStyles = styled.div`
  display: flex;
  max-width: 816px;
  margin: 96px auto;;
  @media (max-width: 890px) {
    display: block;
    text-align: center;
  }
`;

export const ErrorMessageContent = styled.div`
  max-width: 520px;
  @media (max-width: 890px) {
    margin: 0 auto;
  }
`;

export const ErrorMessageTitle = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 16px;
  @media (max-width: 890px) {
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 12px;
  }
`;

export const ErrorMessageText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 20px;
  @media (max-width: 890px) {
    margin-bottom: 24px;
  }
`;

export const ErrorMessageIcon = styled.div`
  margin-right: 50px;
  width: 126px;
  height: 105px;
  @media (max-width: 890px) {
    width: 92px;
    height: 76px;
    margin: 0 auto;
  }
`;
