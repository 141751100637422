import { isClient } from "./isClient";

export default function getMobileOperatingSystem() {
  if (isClient) {
    const userAgent = navigator.userAgent || navigator.vendor || window?.opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }

    if (
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
      || (/Macintosh/i.test(navigator.userAgent) && navigator?.maxTouchPoints && navigator.maxTouchPoints > 1)
    ) {
      return "ios";
    }
  }
  return "desktop";
}
