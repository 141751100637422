import { makeAutoObservable } from "mobx";

class ListPopup {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

   showFirmListPopup = false;
   firmListPopupType = "create";
   currentList = {};
   currentFirmListId = null;

   onOpenCreateListPopup = () => {
    this.showFirmListPopup = true;
    this.firmListPopupType = "create";
  };

   onOpenEditListPopup = (list) => {
    this.currentList = list;
    this.showFirmListPopup = true;
    this.firmListPopupType = "watch";
  };

   onCreateList = (value) => {
    this.currentList = value;
  };

   onCloseListPopup = () => {
    this.rootStore.formsModule.firms.getListsRequest();
    this.showFirmListPopup = false;
  };

   onDeleteList = () => {
    if (this.currentList) {
      this.firmListItemOptions = this.firmListItemOptions.filter((item) => item.id !== this.currentList.id);
    }
    this.currentList = null;
  };

   onChangeList = (value) => {
    this.currentList = value;
  };

   onAddListElement = (value) => {
    this.currentList.elements.push(value);
    this.currentList.total_count = this.currentList.elements.length;
  };

   onDeleteListElement = (value) => {
    this.currentList.elements = this.currentList.elements.filter((item) => item.id === value.id);
    this.currentList.total_count = this.currentList.elements.length;
  };
}

export default ListPopup;
