const en = {
  emailEmptyValidate: "Enter email",
  emailNotValid: "This is not an email. Please check it for typos.",
  subscriptionLimit: "Maximum number of subscriptions exceeded",
  somethingWrong: "Please reload the page, something went wrong.",

  emailRequired: "Enter your email in the format mailbox@example.com",
  emailInvalidDomain: "Mails cannot be delivered to the email address you provided. Please use a different mailbox.",
  emailForbidden: "Do not use our company's domain names",
  emailBlacklist: "This email is blacklisted for breaking website rules. Please specify another email.",
  emailTooLong: "Email length cannot be more than 50 characters",

};

export default en;
