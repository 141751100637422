export const generateSendSubscribePayload = (store) => {
  const payload = {
    routes: [],
  };
  if (store.email) payload.email = store.email;
  if (store.directionFrom?.id) {
    payload.routes.push({
      geoType: store.directionFrom.type,
      geoId: store.directionFrom.id,
      direction: 0,
    });
  }
  if (store.directionTo?.id) {
    payload.routes.push({
      geoType: store.directionTo.type,
      geoId: store.directionTo.id,
      direction: 1,
    });
  }

  return payload;
};

export const onSendSubscribeErrorHandler = (error, store) => {
  switch (error.data.error) {
    case "email_is_not_valid":
      store.emailErrorMessage = store.strings.emailNotValid;
      break;
    case "max_subscriptions_count_limit_exceeded":
      store.rootStore.notificationModule.setNotification({
        type: "error",
        message: store.strings.subscriptionLimit,
      });
      break;
    default:
      store.emailErrorMessage = store.strings.somethingWrong;
      break;
  }
};

export const validationEmailErrorHandler = (error, store) => {
  switch (error.data.error) {
    case "email_forbidden":
      store.emailErrorMessage = store.strings.emailForbidden;
      break;
    case "email_incorrect_format":
      store.emailErrorMessage = store.strings.emailRequired;
      break;
    case "email_invalid_domain":
      store.emailErrorMessage = store.strings.emailInvalidDomain;
      break;
    case "email_too_long":
      store.emailErrorMessage = store.strings.emailTooLong;
      break;
    case "email_is_in_black_list":
      store.emailErrorMessage = store.strings.emailBlacklist;
      break;
    default:
      store.emailErrorMessage = store.strings.somethingWrong;
      break;
  }
};
